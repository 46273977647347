import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IQuiz } from './types';
import { RootState } from '..';
import { getAvailableQuizzes, getAvailableHomeworks, takeQuiz, takeHomework, getStudentTreeViews } from './client';
import { StudentRoutesPrefix } from 'layouts/StudentLayout';

interface StudentState {
  availableQuizzes: {
    quizzes: Types.API.Quiz.IQuizInfo[];
    expiredQuizzes: Types.API.Quiz.IQuizInfo[];
    runningQuizzes: Types.API.QuizTaken.IQuizTakenInfo[];
    finishedQuizzes: Types.API.QuizTaken.IQuizTakenInfo[];
    gradePublishedQuizzes: Types.API.QuizTaken.IQuizTakenInfo[];
  };
  availableHomeworks: {
    homeworks: Types.API.Quiz.IQuizInfo[];
    expiredHomeworks: Types.API.Quiz.IQuizInfo[];
    runningHomeworks: Types.API.QuizTaken.IQuizTakenInfo[];
    finishedHomeworks: Types.API.QuizTaken.IQuizTakenInfo[];
    gradePublishedHomeworks: Types.API.QuizTaken.IQuizTakenInfo[];
  };
  treeViews: Types.API.TreeView.ITreeViewInfo[];
  finishedQuizzes: IQuiz[];
  finishedHomeworks: IQuiz[];
  takeQuizState: { quizTakenId: string; req: Types.IRequestState };
  takeHomeworkState: { quizTakenId: string; req: Types.IRequestState };
  availableQuizzesReq: Types.IRequestState;
  finishedQuizzesReq: Types.IRequestState;
  availableHomeworksReq: Types.IRequestState;
  finishedHomeworksReq: Types.IRequestState;
  treeViewsReq: Types.IRequestState;
}

const initialState: StudentState = {
  takeQuizState: { req: { status: 'idle', error: null }, quizTakenId: '' },
  takeHomeworkState: { req: { status: 'idle', error: null }, quizTakenId: '' },
  availableHomeworks: { homeworks: [], expiredHomeworks: [], runningHomeworks: [], finishedHomeworks: [], gradePublishedHomeworks: [] },
  availableQuizzes: { quizzes: [], expiredQuizzes: [], runningQuizzes: [], finishedQuizzes: [], gradePublishedQuizzes: [] },
  finishedHomeworks: [],
  finishedQuizzes: [],
  availableQuizzesReq: { status: 'idle', error: null },
  finishedQuizzesReq: { status: 'idle', error: null },
  availableHomeworksReq: { status: 'idle', error: null },
  finishedHomeworksReq: { status: 'idle', error: null },
  treeViewsReq: { status: 'idle', error: null },
  treeViews: [],
};

// Async updates to the store
export const getTreeViewsAsync = createAsyncThunk('students/getTreeViews', getStudentTreeViews);
export const getAvailableQuizzesAsync = createAsyncThunk('students/getAvailableQuizzes', getAvailableQuizzes);
export const getAvailableHomeworksAsync = createAsyncThunk('students/getAvailableHomeworks', getAvailableHomeworks);
export const takeQuizAsync = createAsyncThunk('student/takeQuiz', takeQuiz);
export const takeHomeworkAsync = createAsyncThunk('student/takeHomework', takeHomework);

export const slice = createSlice({
  name: 'student',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // Async updates to the store listeners
    builder.addCase(getTreeViewsAsync.pending, (state, action) => {
      state.treeViewsReq.status = 'loading';
    });
    builder.addCase(getTreeViewsAsync.fulfilled, (state, action) => {
      state.treeViewsReq.status = 'succeeded';
      state.treeViews = action.payload;
    });
    builder.addCase(getTreeViewsAsync.rejected, (state, action) => {
      state.treeViewsReq.status = 'failed';
      state.treeViewsReq.error = action.error.message || 'Unknown error';
    });
    builder.addCase(getAvailableQuizzesAsync.pending, (state, _) => {
      state.availableQuizzesReq.status = 'loading';
    });
    builder.addCase(getAvailableQuizzesAsync.fulfilled, (state, action) => {
      state.availableQuizzesReq.status = 'succeeded';
      state.availableQuizzes = action.payload;
    });
    builder.addCase(getAvailableHomeworksAsync.pending, (state, _) => {
      state.availableHomeworksReq.status = 'loading';
    });
    builder.addCase(getAvailableHomeworksAsync.fulfilled, (state, action) => {
      state.availableHomeworksReq.status = 'succeeded';
      state.availableHomeworks = action.payload;
    });
    builder.addCase(takeQuizAsync.pending, (state, action) => {
      state.availableQuizzes.quizzes = state.availableQuizzes.quizzes.filter(q => q.id !== action.meta.arg.quizId);
      state.takeQuizState.req.status = 'loading';
    });
    builder.addCase(takeQuizAsync.fulfilled, (state, action) => {
      state.takeQuizState.req.status = 'succeeded';
      const win = window.open(StudentRoutesPrefix + '/' + action.payload._id + '/quiz', '_blank');
      win?.focus();
    });
    builder.addCase(takeQuizAsync.rejected, (state, action) => {
      state.takeQuizState.req.status = 'failed';
      state.takeQuizState.req.error = action.error.message || 'Unknown error';
    });

    builder.addCase(takeHomeworkAsync.pending, (state, action) => {
      state.availableHomeworks.homeworks = state.availableHomeworks.homeworks.filter(
        q => q.id !== action.meta.arg.quizId,
      );
      state.takeHomeworkState.req.status = 'loading';
    });
    builder.addCase(takeHomeworkAsync.fulfilled, (state, action) => {
      state.takeHomeworkState.req.status = 'succeeded';
      const win = window.open(StudentRoutesPrefix + '/' + action.payload._id + '/homework', '_blank');
      win?.focus();
    });
    builder.addCase(takeHomeworkAsync.rejected, (state, action) => {
      state.takeHomeworkState.req.status = 'failed';
      state.takeHomeworkState.req.error = action.error.message || 'Unknown error';
    });
  },
});

// export const {} = slice.actions;

export const availableQuizzesSelector = (state: RootState) => state.student.availableQuizzes;
export const takeQuizStateSelector = (state: RootState) => state.student.takeQuizState;
export const finishedQuizzesSelector = (state: RootState) => state.student.finishedQuizzes;
export const availableQuizzesReqSelector = (state: RootState) => state.student.availableQuizzesReq;
export const availableHomeworkselector = (state: RootState) => state.student.availableHomeworks;
export const finishedHomeworksSelector = (state: RootState) => state.student.finishedHomeworks;
export const availableHomeworksSelector = (state: RootState) => state.student.availableHomeworks;
export const takeHomworkStateSelector = (state: RootState) => state.student.takeHomeworkState;
export const finishedHomeworSelector = (state: RootState) => state.student.finishedHomeworks;
export const availableHomeworksReqSelector = (state: RootState) => state.student.availableHomeworksReq;
export default slice.reducer;
