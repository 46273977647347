import { useEffect, useState } from 'react';

/**
 *
 * @param {String} VdocipherAPIScriptUrl
 */
function useVdocipher() {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  useEffect(() => {
    if (isScriptLoaded) return;
    console.log('Loading');
    const script = document.createElement('script');
    script.onload = () => setIsScriptLoaded(true);
    script.src = 'https://player.vdocipher.com/v2/api.js';
    document.body.append(script);
  });

  return {
    loadVideo: function ({
      otp,
      playbackInfo,
      container,
      configuration = {},
    }: {
      otp: string;
      playbackInfo: string;
      container: any;
      configuration?: any;
    }) {
      const params = new URLSearchParams('');
      const player = 'rhjJVIAOdqgcaUh4';

      const parametersToAdd = { otp, playbackInfo, player, ...configuration };
      for (const item in parametersToAdd) {
        params.append(item, parametersToAdd[item]);
      }
      const iframe = document.createElement('iframe');
      // iframe.setAttribute('allowfullscreen', 'true');
      iframe.setAttribute('allow', 'autoplay; fullscreen; encrypted-media');
      iframe.setAttribute('frameborder', '0');
      iframe.style.height = '100%';
      iframe.style.width = '100%';
      iframe.style.overflow = 'auto';

      iframe.src = 'https://player.vdocipher.com/v2/?' + params;
      container.append(iframe);
      return iframe;
    },
    isAPIReady: isScriptLoaded,
  };
}
export default useVdocipher;
