import React from 'react';
import { ColDef, DataGrid, SelectionChangeParams } from '@material-ui/data-grid';
import CloudDownload from '@material-ui/icons/CloudDownload';
import VeryBadIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import BadIcon from '@material-ui/icons/SentimentDissatisfied';
import NormalIcon from '@material-ui/icons/SentimentSatisfied';
import GoodIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import VeryGoodIcon from '@material-ui/icons/SentimentVerySatisfied';

import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from 'features/auth/authSlice';
import { useReqListener } from 'hooks/useReqListener';
import Button from 'components/CustomButtons/Button';
import { getStudentTasksAsync, studentTasksSelector, taskCorrectionSelector } from '../slices/adminSlice';

import styles from 'assets/jss/lms/features/questions';
import { makeStyles, TextField } from '@material-ui/core';
import CardHeader from 'components/Card/CardHeader';
import { useParams } from 'react-router-dom';
import CustomAutocomplete, { OptionType } from 'components/Autocomplete/Autocomplete';
import classNames from 'classnames';
import { downloadStudentReport, getStudentMarkFromAnswerModels, getTotalMarkFromAnswerModels } from 'features/helpers';
import { Box } from '@mui/system';

const useStyles = makeStyles(styles);

interface Props {}
const StudentTasksManagement: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const token = useSelector(tokenSelector);
  const [taskNameFilter, setTaskNameFilter] = React.useState<string>('');
  const [startDateFilter, setStartDateFilter] = React.useState<Date | undefined>(undefined);
  const [endDateFilter, setEndDateFilter] = React.useState<Date | undefined>(undefined);

  const studentTasks = useSelector(studentTasksSelector).tasks;
  const studentDetails = useSelector(studentTasksSelector).studentDetails;
  const studentTasksReq = useSelector(studentTasksSelector).req;

  const { studentId } = useParams<{ studentId: string }>();

  const statusOptions = [
    { name: 'Not Started', id: 'Not Started' },
    { name: 'Started', id: 'Started' },
    { name: 'Submitted', id: 'Submitted' },
    { name: 'All', id: 'All' },
  ];
  const [selectedStatusFilter, setSelectedStatusFilter] = React.useState<OptionType>(statusOptions[3]);

  const lateOptions = [
    { name: 'Yes', id: 'Yes' },
    { name: 'No', id: 'No' },
    { name: 'All', id: 'All' },
  ];
  const [selectedLateFilter, setSelectedLateFilter] = React.useState<OptionType>(lateOptions[2]);

  const isHomeworkOptions = [
    { name: 'Homework', id: 'Homework' },
    { name: 'Quiz', id: 'Quiz' },
    { name: 'All', id: 'All' },
  ];
  const [selectedIsHomeworkFilter, setSelectedIsHomeworkFilter] = React.useState<OptionType>(isHomeworkOptions[2]);

  const setLateText = (value: any, dueDate: any): string => {
    if (value === undefined) {
      value = new Date(dueDate) < new Date();
    }
    return value ? 'Yes' : 'No';
  };

  const setStatusText = (value: any): string => {
    if (value === undefined) return 'Not Started';
    return value === 'started' ? 'Started' : 'Submitted';
  };

  const setIndicationText = (status: string, isLate: string, forDownload = false): any => {
    const getIndication = (indication: string): React.ReactNode => {
      const icons = {
        'Very Bad': <VeryBadIcon className={classes.badIcon} />,
        Bad: <BadIcon className={classes.badIcon} />,
        Normal: <NormalIcon className={classes.normalIcon} />,
        Good: <GoodIcon className={classes.goodIcon} />,
        'Very Good': <VeryGoodIcon className={classes.goodIcon} />,
      };
      if (forDownload) return indication;

      return (
        <>
          {indication}
          {icons[indication]}
        </>
      );
    };

    switch (status) {
      case 'Not Started':
        return getIndication(isLate === 'Yes' ? 'Very Bad' : 'Normal');
      case 'Started':
        return getIndication(isLate === 'Yes' ? 'Bad' : 'Good');
      case 'Submitted':
        return getIndication(isLate === 'Yes' ? 'Good' : 'Very Good');

      default:
        return '';
    }
  };
  const setSummaryText = (status: string, isLate: string): any => {
    switch (status) {
      case 'Not Started':
        return isLate === 'Yes' ? 'Deadline Reached, Untouched Quiz.' : 'Deadline has not come yet.';
      case 'Started':
        return isLate === 'Yes' ? 'Deadline Reached, Incomplete Work.' : 'In progress.';
      case 'Submitted':
        return isLate === 'Yes' ? 'Late Submission.' : 'Early Submission.';

      default:
        return '';
    }
  };
  const setGradeText = (answers: any, gradePublished: any, status: any) => {
    if (!answers || status !== 'ended') return '';
    const totalMark = getTotalMarkFromAnswerModels(answers);
    if (totalMark === 0) return '';

    if (!gradePublished && status === 'ended') return 'In review';
    const studentsMark = getStudentMarkFromAnswerModels(answers);
    return studentsMark + '/' + totalMark;
  };

  const columns: ColDef[] = [
    { field: 'index', headerName: 'ID', width: 90 },
    { field: 'name', headerName: 'Task Name', width: 250 },
    { field: 'isHomework', headerName: 'Quiz/Homework', width: 170 },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
    },
    {
      field: 'isLate',
      headerName: 'Late?',
      width: 130,
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      width: 220,
      valueFormatter: v => new Date(v.value as string).toUTCString().substring(0, 25),
    },
    {
      field: 'submittedDate',
      headerName: 'Last Activity',
      width: 220,
      valueFormatter: v => (v.value ? new Date(v.value as string).toUTCString().substring(0, 25) : undefined),
    },
    // { field: 'assistantReviewed', headerName: 'Reviewed?', width: 125, hide: selectedStatusFilter.id !== '2' },
    // { field: 'gradePublished', headerName: 'Published?', width: 125 },
    {
      field: 'grade',
      headerName: 'Grade',
      width: 125,
    },
    {
      field: 'summary',
      headerName: 'Summary',
      width: 270,
      renderCell: v => setSummaryText(v.row.status, v.row.isLate),
    },
    // {
    //   field: 'indication',
    //   headerName: 'Indication',
    //   width: 150,
    //   renderCell: v => setIndicationText(v.row.status, v.row.isLate),
    // },
  ];

  let rows = studentTasks.map((q, index) => ({
    index: index + 1,
    id: q._id,
    name: q.name,
    isHomework: q.isHomework ? 'Homework' : 'Quiz',
    status: setStatusText(q.status),
    isLate: setLateText(q.isLate, q.dueDate),
    // isLate: q?.isLate ? q.isLate : new Date(q.dueDate) < new Date(),
    dueDate: q?.dueDate && q.dueDate,
    submittedDate: q?.submittedDate && q.submittedDate,
    // assistantReviewed: q?.instructorGraded && q.instructorGraded,
    // gradePublished: q?.gradePublished && q.gradePublished,
    grade: setGradeText(q?.answers, q.gradePublished, q.status),
    // grade:
    //   q?.answers && getTotalMarkFromAnswerModels(q.answers) !== 0 && q.gradePublished
    //     ? getStudentMarkFromAnswerModels(q.answers) + '/' + getTotalMarkFromAnswerModels(q.answers)
    //     : '',
    summary: '',
    // indication: '',
  }));

  let filteredQuizzes = rows;

  if (taskNameFilter && taskNameFilter !== '') {
    filteredQuizzes = filteredQuizzes.filter(q => q && q.name?.toLowerCase().includes(taskNameFilter.toLowerCase()));
  }

  if (startDateFilter) {
    filteredQuizzes = filteredQuizzes.filter(q => q && q.dueDate && new Date(q.dueDate) > startDateFilter);
  }

  if (endDateFilter) {
    filteredQuizzes = filteredQuizzes.filter(q => q && q.dueDate && new Date(q.dueDate) < endDateFilter);
  }

  if (selectedStatusFilter && selectedStatusFilter.name !== 'All') {
    filteredQuizzes = filteredQuizzes.filter(
      q => (q.status && q.status === selectedStatusFilter.id) || (!q.status && selectedStatusFilter.id == ''),
    );
  }

  if (selectedLateFilter && selectedLateFilter.name !== 'All') {
    filteredQuizzes = filteredQuizzes.filter(q => q.isLate === selectedLateFilter.id);
  }

  if (selectedIsHomeworkFilter && selectedIsHomeworkFilter.name !== 'All') {
    filteredQuizzes = filteredQuizzes.filter(q => q.isHomework === selectedIsHomeworkFilter.id);
  }

  useReqListener({
    status: studentTasksReq.status,
    successMessage: 'Successfully fetched student tasks',
    loadingMessage: 'Fetching student tasks',
    errorMessage: studentTasksReq.error,
  });

  React.useEffect(() => {
    if (studentId && dispatch && studentTasksReq.status === 'idle') {
      dispatch(getStudentTasksAsync({ studentId: studentId, token }));
    }
  }, [studentId, dispatch, studentTasksReq.status, token]);

  const handleDownloadReport = () => {
    const newRows = filteredQuizzes.map(({ ['id']: _, ...r }) => ({
      ...r,
      dueDate: new Date(r.dueDate as string).toUTCString().substring(0, 25),
      submittedDate: r.submittedDate ? new Date(r.submittedDate as string).toUTCString().substring(0, 25) : undefined,
      // status: !r.status ? 'Not Started' : r.status === 'started' ? 'Started' : 'Submitted',
      summary: setSummaryText(r.status, r.isLate),
      // indication: setIndicationText(r.status, r.isLate, true),
    }));
    const [firstName, secondName, lastName] = studentDetails.name.split(' ');
    const finalName = lastName ? `${firstName}_${secondName}_${lastName}` : `${firstName}_${secondName}`;
    downloadStudentReport(newRows, columns, finalName);
  };

  return (
    <div
      style={{ height: '80vh', width: '100%', display: 'flex', flexDirection: 'column', alignContent: 'flex-start' }}
    >
      <CardHeader
        color="primary"
        className={classes.cardHeader}
        style={{
          marginTop: '20px',
        }}
      >
        {studentDetails.name && (
          <h6 style={{ fontWeight: 'bolder', fontSize: 'large' }}> {studentDetails.name}'s Progress</h6>
        )}
      </CardHeader>

      <div
        className={classNames(classes.container, classes.categoryAutocomplete)}
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
          <TextField
            style={{ padding: '5px', margin: '5px', marginLeft: '20px' }}
            label="Task Name Filter"
            onChange={e => setTaskNameFilter(e.target.value)}
          />

          <Box sx={{ width: '12rem' }}>
            <CustomAutocomplete
              value={selectedIsHomeworkFilter || isHomeworkOptions[2]}
              // multiple
              placeholder="Quiz/Homework"
              onChange={(event: React.ChangeEvent<{}>, value: OptionType) => setSelectedIsHomeworkFilter(value)}
              id="Quiz/Homework"
              labelText="Quiz/Homework"
              options={isHomeworkOptions}
            />
          </Box>

          <Box sx={{ width: '12rem' }}>
            <CustomAutocomplete
              value={selectedStatusFilter || statusOptions[3]}
              // multiple
              placeholder="Status"
              onChange={(event: React.ChangeEvent<{}>, value: OptionType) => setSelectedStatusFilter(value)}
              id="Status"
              labelText="Status"
              options={statusOptions}
            />
          </Box>
          <Box sx={{ width: '12rem' }}>
            <CustomAutocomplete
              value={selectedLateFilter || lateOptions[2]}
              // multiple
              placeholder="Late"
              onChange={(event: React.ChangeEvent<{}>, value: OptionType) => setSelectedLateFilter(value)}
              id="Late"
              labelText="Late"
              options={lateOptions}
            />
          </Box>

          <TextField
            id="date"
            style={{ padding: '5px', margin: '5px', marginLeft: '20px' }}
            label="From"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e =>
              e.target.value !== '' ? setStartDateFilter(new Date(e.target.value)) : setStartDateFilter(undefined)
            }
          />
          <TextField
            id="date"
            style={{ padding: '5px', margin: '5px', marginLeft: '20px' }}
            label="To"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e =>
              e.target.value !== '' ? setEndDateFilter(new Date(e.target.value)) : setEndDateFilter(undefined)
            }
          />
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button color="success" round onClick={handleDownloadReport}>
            Download Report <CloudDownload style={{ marginLeft: '8px' }} className={classes.icons} />
          </Button>
        </div>
      </div>

      <DataGrid
        rows={filteredQuizzes}
        pagination
        columns={columns}
        density="comfortable"
        // showToolbar
        autoHeight
        // checkboxSelection
        // disableMultipleSelection
        // hideFooterSelectedRowCount
        // disableSelectionOnClick
        loading={studentTasksReq.status === 'loading'}
      />
    </div>
  );
};

export default StudentTasksManagement;
