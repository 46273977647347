import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
// import Camera from '@material-ui/icons/Camera';
import Quiz from '@material-ui/icons/Help';
import Assignment from '@material-ui/icons/Assignment';
import VideoLibrary from '@material-ui/icons/VideoLibrary';
import MicIcon from '@material-ui/icons/Mic';
// import Palette from '@material-ui/icons/NotificationsActiveOutlined';
// import DoneAllOutlined from '@material-ui/icons/DoneAllOutlined';
// core components
import Header from 'components/Header/Header';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import HeaderLinks from 'components/Header/HeaderLinks';
import NavPills from 'components/NavPills/NavPills';
import Parallax from 'components/Parallax/Parallax';

import profile from 'assets/img/faces/avatar.png';
import studentBg from 'assets/img/cover2.jpg';

import styles from 'assets/jss/lms/views/student/profilePage';
import { tokenSelector, userSelector } from 'features/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import AvailableQuizzes from './AvailableQuizzes';
import { useReqListener, useViewAuth } from 'hooks';
import {
  availableQuizzesReqSelector,
  availableQuizzesSelector,
  availableHomeworksReqSelector,
  availableHomeworksSelector,
  getAvailableQuizzesAsync,
  getAvailableHomeworksAsync,
  getTreeViewsAsync,
} from './studentSlice';
import { resetAnswerQuizState } from 'features/quiz/updateSlice';
import { RootState } from 'features';
//import TreeNode from 'components/TreeView/TreeNode';
import {
  availableVimVideosSelector,
  getVdoCipherAsync,
  getVdoCipherVideosReqSelector,
  getVdoCipherVideosSelector,
  getVimVideosAsync,
} from 'features/admin/slices/adminSlice';
import ResetPassword from 'features/auth/ResetPassword';
import AccordionContent from './AccordionContent';
const useStyles = makeStyles(styles);

const ProfilePage: React.FC = () => {
  useViewAuth();
  const classes = useStyles();
  const user = useSelector(userSelector);
  const token = useSelector(tokenSelector);
  const availableQuizzesReq = useSelector(availableQuizzesReqSelector);
  const availableQuizzes = useSelector(availableQuizzesSelector);
  const availableHomeworksReq = useSelector(availableHomeworksReqSelector);
  const availableHomeworks = useSelector(availableHomeworksSelector);
  const imageClasses = classNames(classes.imgRaised, classes.imgRoundedCircle, classes.imgFluid);
  //const treeViews = useSelector((state: RootState) => state.student.treeViews);
  const getTreeViewsReq = useSelector((state: RootState) => state.student.treeViewsReq);
  const allVideos = useSelector(availableVimVideosSelector);
  const getVideosReq = useSelector((state: RootState) => state.admin.vimVideos.req);

  const vdoCipherVideos = useSelector(getVdoCipherVideosSelector);
  const vdoCipherVideosReq = useSelector(getVdoCipherVideosReqSelector);

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleExpansion = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  useReqListener({
    status: availableQuizzesReq.status,
    successMessage: 'Successfully fetched all the quizzes from the backend',
    loadingMessage: 'Fetching the Quizzes from the backend',
    errorMessage: availableQuizzesReq.error,
  });
  useReqListener({
    status: availableHomeworksReq.status,
    successMessage: 'Successfully fetched all the Homeworks from the backend',
    loadingMessage: 'Fetching the Homeworks from the backend',
    errorMessage: availableHomeworksReq.error,
  });
  useReqListener({
    status: getTreeViewsReq.status,
    successMessage: 'Successfully fetched all the treeViews from the backend',
    loadingMessage: 'Fetching the treeViews from the backend',
    errorMessage: getTreeViewsReq.error,
  });
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (user.loggedInBefore) {
      if (dispatch && availableQuizzesReq.status === 'idle') {
        token && dispatch(getAvailableQuizzesAsync({ token }));
        dispatch(resetAnswerQuizState()); //resetting last session
      }
      if (dispatch && availableHomeworksReq.status === 'idle') {
        token && dispatch(getAvailableHomeworksAsync({ token }));
        dispatch(resetAnswerQuizState()); //resetting last session
      }
      // token && getVideosReq.status === 'idle' && dispatch(getVimVideosAsync({ token }));
      token &&
        vdoCipherVideosReq.status === 'idle' &&
        dispatch(getVdoCipherAsync({ token, key: 'groups', q: user.group?.id }));
      token && getTreeViewsReq.status === 'idle' && dispatch(getTreeViewsAsync({ token }));
    }
  }, [
    availableHomeworksReq.status,
    availableQuizzesReq.status,
    // getVideosReq.status,
    vdoCipherVideosReq.status,
    getTreeViewsReq.status,
    dispatch,
    token,
    user.loggedInBefore,
  ]);

  return (
    <div>
      <Header
        color="transparent"
        rightLinks={<HeaderLinks />}
        brand=""
        fixed
        changeColorOnScroll={{
          height: 400,
          color: 'primary',
        }}
      />
      <Parallax small filter image={studentBg} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>{user.name}</h3>
                    <h6 className={classes.email}>{user.email}</h6>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              {!user.loggedInBefore && user.role === 'student' ? (
                <ResetPassword />
              ) : (
                <GridItem xs={12} sm={12} md={12} className={classes.navWrapper}>
                  <NavPills
                    alignCenter
                    color="primary"
                    setExpanded={setExpanded}
                    tabs={[
                      {
                        tabButton: 'Videos',
                        tabIcon: VideoLibrary,
                        // tabContent: <VideoView allVideos={allVideos} />,
                        tabContent: [
                          <AccordionContent
                            data={vdoCipherVideos.filter(v => v.type !== 'vdo-speaking')}
                            type="vdo-lect"
                            key="vdo-lect"
                            expanded={expanded}
                            handleExpansion={handleExpansion}
                            panel="vdo-lect"
                          />,
                        ],
                      },
                      {
                        tabButton: 'Speaking',
                        tabIcon: MicIcon,
                        tabContent: [
                          <AccordionContent
                            data={vdoCipherVideos.filter(v => v.type === 'vdo-speaking')}
                            type="vdo-speaking"
                            key="vdo-speaking"
                            expanded={expanded}
                            handleExpansion={handleExpansion}
                            panel="vdo-speaking"
                            isLoading={vdoCipherVideosReq.status === 'loading'}
                          />,
                        ],
                      },
                      {
                        tabButton: 'Quizzes',
                        tabIcon: Quiz,
                        tabContent: [
                          <AccordionContent
                            data={availableQuizzes.quizzes}
                            type="available"
                            key="available"
                            expanded={expanded}
                            handleExpansion={handleExpansion}
                            panel="available-quizzes"
                            isLoading={availableQuizzesReq.status === 'loading'}
                          />,
                          <AccordionContent
                            data={availableQuizzes.runningQuizzes}
                            type="running"
                            key="running"
                            expanded={expanded}
                            handleExpansion={handleExpansion}
                            panel="running-quizzes"
                            isLoading={availableQuizzesReq.status === 'loading'}
                          />,
                          <AccordionContent
                            data={availableQuizzes.expiredQuizzes}
                            type="late"
                            key="late"
                            expanded={expanded}
                            handleExpansion={handleExpansion}
                            panel="late-quizzes"
                            isLoading={availableQuizzesReq.status === 'loading'}
                          />,
                          <AccordionContent
                            data={availableQuizzes.gradePublishedQuizzes}
                            type="published"
                            key="published"
                            expanded={expanded}
                            handleExpansion={handleExpansion}
                            panel="published-quizzes"
                            isLoading={availableQuizzesReq.status === 'loading'}
                          />,
                        ],
                      },
                      {
                        tabButton: 'Homework',
                        tabIcon: Assignment,
                        tabContent: [
                          <AccordionContent
                            data={availableHomeworks.homeworks}
                            type="available"
                            key="available"
                            expanded={expanded}
                            handleExpansion={handleExpansion}
                            panel="available-homework"
                            isLoading={availableHomeworksReq.status === 'loading'}
                          />,
                          <AccordionContent
                            data={availableHomeworks.runningHomeworks}
                            type="running"
                            key="running"
                            expanded={expanded}
                            handleExpansion={handleExpansion}
                            panel="running-homework"
                            isLoading={availableHomeworksReq.status === 'loading'}
                          />,
                          <AccordionContent
                            data={availableHomeworks.expiredHomeworks}
                            type="late"
                            key="late"
                            expanded={expanded}
                            handleExpansion={handleExpansion}
                            panel="late-homework"
                            isLoading={availableHomeworksReq.status === 'loading'}
                          />,
                          <AccordionContent
                            data={availableHomeworks.gradePublishedHomeworks}
                            type="published"
                            key="published"
                            expanded={expanded}
                            handleExpansion={handleExpansion}
                            panel="published-homework"
                            isLoading={availableHomeworksReq.status === 'loading'}
                          />,
                        ],
                      },
                    ]}
                  />
                </GridItem>
              )}
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
