const config = {
  //? for production

  apiBaseUrl: 'https://igcse-esl.org/api',
  //socketBaseUrl: 'https://igcse-esl.org',
  frontBaseUrl: 'https://igcse-esl.org/admin',
  frontBase: 'https://igcse-esl.org',

  //! for development

  // apiBaseUrl: 'http://localhost:3001/api',
  // //socketBaseUrl: 'http://localhost:3001',
  // frontBaseUrl: 'http://localhost:3000/admin',
  // frontBase: 'http://localhost:3000',
};

export default config;
