import ReactDOM from 'react-dom';
import { store } from 'features';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
//import io from 'socket.io-client';
import {
  faCheckSquare,
  faCoffee,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faStopCircle,
  faMicrophone,
  faPlusSquare,
  faRecordVinyl,
  faSignOutAlt,
  faUserGraduate,
  faCheckCircle,
  faTimesCircle,
  faEnvelope,
  faUser,
  faLock,
  faPhone,
  faSchool,
  faGraduationCap,
  faVideo,
  faAngleDoubleDown,
  faAngleDoubleUp,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import './assets/scss/lms-react.scss?v=1.9.0';

import * as serviceWorker from './serviceWorker';

/* This is a temp fix for filters of the data grid
Issue is still open
https://github.com/mui-org/material-ui-x/issues/844
once closed, this fix should be removed
*/
import './index.css';

// Core Components, Main Layouts
import AdminLayout from './layouts/AdminLayout';
import ParentLayout from './layouts/ParentLayout';
import StudentLayout from './layouts/StudentLayout';
import LandingLayout from './layouts/LandingLayout';
import VideoLayout from 'layouts/VideoLayout';
import AssistantLayout from 'layouts/AssistantLayout';

// const hist = createBrowserHistory();
library.add(
  fab,
  faCheckSquare,
  faCoffee,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faStopCircle,
  faMicrophone,
  faPlusSquare,
  faRecordVinyl,
  faSignOutAlt,
  faUserGraduate,
  faCheckCircle,
  faTimesCircle,
  faEnvelope,
  faUser,
  faLock,
  faPhone,
  faSchool,
  faGraduationCap,
  faVideo,
  faAngleDoubleDown,
  faAngleDoubleUp,
  faTrash,
);
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <Switch>
        <Route path="/admin">
          <AdminLayout />
        </Route>
        <Route path="/assistant">
          <AssistantLayout />
        </Route>
        <Route path="/student">
          <StudentLayout />
        </Route>
        <Route path="/parent">
          <ParentLayout />
        </Route>
        <Route path="/landing">
          <LandingLayout />
        </Route>
        <Route path="/video">
          <VideoLayout />
        </Route>
        <Redirect to="/landing" />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
