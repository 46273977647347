/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import './style.css';
import { ISubtitle } from './VideoPlayer';
import SpeakLinesModalProps from './SpeakLinesModal';
import { Status } from './VideoPlayer';
import Subtitle from './Subtitle';
import { useDispatch, useSelector } from 'react-redux';
import { getVideoCurrentTimeSelector, setIsVideoPaused, setVideoCurrentTime } from 'features/admin/slices/adminSlice';
import CommentInput from 'features/video/CommentInput';

declare global {
  interface Window {
    VdoPlayer: any;
    player: {
      video: any;
    };
  }
}

interface VideoStatusAndSubtitleProps {
  videoRef: HTMLIFrameElement | null;
  isAPIReady: boolean;
  pausesRef: React.MutableRefObject<number[]>;
  subtitlesRef: React.MutableRefObject<ISubtitle[]>;
  subtitle: ISubtitle | null;
  allSubtitles: ISubtitle[];

  setSubtitle: (subtitle: ISubtitle) => void;
}

const VideoStatusAndSubtitle: React.FC<VideoStatusAndSubtitleProps> = ({
  videoRef,
  isAPIReady,
  pausesRef,
  subtitlesRef,
  subtitle,
  setSubtitle,
  allSubtitles,
}) => {
  const [status, setStatus] = useState<Status>(Status.NA);
  const [isSpeaking, setIsSpeaking] = useState<boolean>(false);
  const [player, setPlayer] = useState<any | null>(null);
  const dispatch = useDispatch();
  const videoCurrentTime = useSelector(getVideoCurrentTimeSelector);

  useEffect(() => {
    if (player?.video?.currentTime) {
      subtitlesRef.current = allSubtitles;
      player.video.currentTime = videoCurrentTime;
    }
  }, [videoCurrentTime]);

  useEffect(() => {
    if (player || !isAPIReady) return;
    if (!videoRef) {
      setPlayer(null);
      return;
    }
    const customplayer = new window.VdoPlayer(videoRef as HTMLIFrameElement);
    window.player = window.player || customplayer;

    setPlayer(customplayer);

    window.player = customplayer;
  }, [isAPIReady, videoRef]);

  useEffect(() => {
    if (!player || !player.video) return;

    player.video.addEventListener('play', () => {
      if (isSpeaking) return;
      setStatus(Status.Playing);
    });
    player.video.addEventListener('pause', () => {
      setStatus(Status.Paused);
    });
    player.video.addEventListener('canplay', () => {
      if (isSpeaking) return;

      setStatus(Status.Ready);
    });
    player.video.addEventListener('ended', () => {
      if (isSpeaking) return;
      //alert('ended');
      // TODO: api call
    });

    let previousTime = 0;
    player.video.addEventListener('timeupdate', () => {
      if (isSpeaking) return;
      const currentTime = Math.floor(player.video.currentTime) as number;
      //detect the backward seeking
      if (currentTime < previousTime && allSubtitles) {
        // Do something when backward seeking is detected
        player.video.pause();
        const foundSubtitle = allSubtitles.find(item => item.start < currentTime && item.end >= currentTime);
        if (foundSubtitle) {
          // Update the current property of the mutable ref to the new value when we seek back
          const foundSubtitleIndex = allSubtitles.findIndex(
            subtitle => subtitle.start === foundSubtitle.start && subtitle.end === foundSubtitle.end,
          );
          subtitlesRef.current = [...allSubtitles.slice(foundSubtitleIndex)];
          pausesRef.current = [...allSubtitles.slice(foundSubtitleIndex).map(subtitle => subtitle.end)];
          setSubtitle(foundSubtitle);
        }
      }
      previousTime = currentTime;
      let currentSubtitle = subtitle;
      if (status === Status.Playing) {
        // if the video is playing, check if the current time is in the range of any subtitle
        currentSubtitle =
          subtitlesRef.current.find(item => item.start < currentTime && item.end >= currentTime) ?? null;

        if (currentSubtitle && currentSubtitle !== subtitle) {
          //remove the first item from the array since the array is sorted
          subtitlesRef.current && subtitlesRef.current.shift();

          setSubtitle(currentSubtitle);
        }
      }
      if (currentSubtitle || subtitle) {
        // if the current time is in the range of any pause, pause the video
        const currentPause = pausesRef.current.find(time => time - 0.5 < currentTime && time >= currentTime);
        if (currentPause) {
          pausesRef.current && pausesRef.current.shift();
          player.video.pause();
          setIsSpeaking(true);
        }
      }
    });

    dispatch(setIsVideoPaused(player.video.paused));
    dispatch(setVideoCurrentTime(player.video.currentTime));

    () => {
      if (!player || !player.video) return;
      player?.video.removeEventListener('play');
      player?.video.removeEventListener('pause');
      player?.video.removeEventListener('canplay');
      player?.video.removeEventListener('timeupdate');
    };
  }, [player, status, subtitle, subtitlesRef, pausesRef]);

  function pauseVideo() {
    if (!player || !player.video) return;
    player.video.pause();
  }
  function playVideo() {
    if (!player || !player.video) return;
    player.video.play();
  }

  // speak the lines submition
  const onSubmit = () => {
    const currentTime = player.video.currentTime as number;

    let currentSubtitle = subtitle;
    //  the video is playing, check if the current time is in the range of any subtitle
    currentSubtitle = subtitlesRef.current.find(item => item.start < currentTime && item.end >= currentTime) ?? null;

    if (currentSubtitle && currentSubtitle !== subtitle) {
      //remove the first item from the array since the array is sorted
      subtitlesRef.current && subtitlesRef.current.shift();
      setSubtitle(currentSubtitle);
    }
    player?.video?.play();
    setIsSpeaking(false);
  };

  return player && player.video ? (
    <>
      <div className="subtitles">
        {/* FADY ASKED US TO COMMENT IT TO AVOID THE DOUBLE APPEARANCE */}
        {/* <Subtitle
          subtitle={subtitle}
          status={status}
          isSpeaking={isSpeaking}
          playVideo={playVideo}
          pauseVideo={pauseVideo}
        /> */}
      </div>
      <SpeakLinesModalProps
        // isOpen={isSpeaking && subtitle !== null && subtitle?.text !== ''}
        isOpen={isSpeaking && subtitle !== null}
        subtitle={subtitle?.text}
        subtitleEnd={subtitle?.end}
        onSubmit={onSubmit}
      />
    </>
  ) : null;
};

export default VideoStatusAndSubtitle;
