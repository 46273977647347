import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, InputAdornment } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircle';
import Autocomplete, { OptionType } from 'components/Autocomplete/Autocomplete';
import CustomInput from 'components/CustomInput/CustomInput';
import { tokenSelector } from 'features/auth/authSlice';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  availableGroupsSelector,
  getGroupsReqSelector,
  setCreateVdoCipherInputs,
  createVdoCipherInputsSelector,
  createVdoCipherAsync,
  uploadUsingCredentialsAsync,
  getGroupsAsync,
  editVdoCipherAsync,
} from '../slices/adminSlice';
import { Button, Grid, IconButton, TextField } from '@mui/material';
// import Button from 'components/CustomButtons/Button';
import CustomButton from 'components/CustomButtons/Button';

import CustomDropzone from 'components/CustomDropzone/CustomDropzone';
import GridContainer from 'components/Grid/GridContainer';
import VideoSubtitleInputs from './VideoSubtitleInputs';
import CustomAutocomplete from 'components/Autocomplete/Autocomplete';
import ContentSubmitDialog from 'components/Dialog/ContentSubmitDialog';
import { RootState } from 'features';
import NavPills from 'components/NavPills/NavPills';
import VideoLibrary from '@material-ui/icons/VideoLibrary';

interface Props {
  openDialogue: boolean;
  setOpenDialogue: React.Dispatch<React.SetStateAction<boolean>>;
  edit: boolean;
  videoId?: string;
  vdoCipherId?: string;
  type: 'vdo-lect' | 'vdo-speaking';
}

const VideoForm: React.FC<Props> = ({ openDialogue, setOpenDialogue, edit, videoId, vdoCipherId, type }) => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const groups = useSelector(availableGroupsSelector);
  const getGroupsReq = useSelector(getGroupsReqSelector);
  const createVdoCipherInputs = useSelector(createVdoCipherInputsSelector);
  const credentials = useSelector((state: RootState) => state.admin.uploadVdoCipher.credentials);
  const createVdoCipherReq = useSelector((state: RootState) => state.admin.uploadVdoCipher.req);
  const editVdoCipherReq = useSelector((state: RootState) => state.admin.editVdoCipherReq);

  const [file, setFile] = React.useState<File>();
  const [openDropzone, setOpenDropzone] = React.useState(false);

/*   const videoTypes: OptionType[] = [
    { id: 'vdo-lect', name: 'Video Lecture' },
    { id: 'vdo-speaking', name: 'Video Speaking' },
  ]; */

  const onSave = (acceptedFiles: File[]) => {
    setOpenDropzone(false);
    setFile(acceptedFiles[0]);
  };

  const resetForm = () => {
    setFile(undefined);
    dispatch(
      setCreateVdoCipherInputs({
        title: '',
        description: '',
        assignedDate: new Date('1970-01-01'),
        groups: [],
        type: type,
        subtitles: []
      }),
    );
  };

  const renderDate = () => {
    const year = createVdoCipherInputs.assignedDate.getFullYear();
    const month = String(createVdoCipherInputs.assignedDate.getMonth() + 1).padStart(2, '0'); // Adding 1 to month as it's zero-based

    if (year === 1970) return undefined;
    return `${year}-${month}`;
  };

  const handleSubmitUpload = () => {
    edit ? handleEditVideoSubmit() : handleCreateVideoSubmit();
  };

  const handleCreateVideoSubmit = () => {
    const clonedCreateVdoCipherInputs = {
      ...createVdoCipherInputs, type: type,
      subtitles: type === 'vdo-lect' ? [] : createVdoCipherInputs.subtitles,
};

    if (file && clonedCreateVdoCipherInputs.vdoCipherId) 
       delete clonedCreateVdoCipherInputs.vdoCipherId;

    dispatch(
        createVdoCipherAsync({
          token,
          reqBody: clonedCreateVdoCipherInputs,
        }),
      );
      setOpenDialogue(false);
  };

  const handleEditVideoSubmit = () => {
    if (videoId && vdoCipherId)
      dispatch(
        editVdoCipherAsync({
          token,
          videoId,
          vdoCipherId,
          reqBody: createVdoCipherInputs,
          fileEdited: file ? true : false,
        }),
      );
    setOpenDialogue(false);
  };

  React.useEffect(() => {
    if (createVdoCipherReq.status === 'succeeded') {
      if (file) {
        dispatch(
          uploadUsingCredentialsAsync({
            token,
            credentials,
            file,
          }),
        );
      }
      resetForm();
    }
  }, [createVdoCipherReq.status, dispatch]);

  React.useEffect(() => {
    if (editVdoCipherReq.status === 'succeeded') {
      if (file) {
        dispatch(
          uploadUsingCredentialsAsync({
            token,
            credentials,
            file,
          }),
        );
      }
      resetForm();
    }
  }, [editVdoCipherReq.status, dispatch]);

  React.useEffect(() => {
    token && getGroupsReq.status === 'idle' && dispatch(getGroupsAsync({ token }));
  }, [token, getGroupsReq.status, dispatch]);

  return (
    <>
      <ContentSubmitDialog
        open={openDialogue}
        dialogTitle={`${edit ? 'Edit Video' : 'Create A New Video'} ${type === 'vdo-lect'? 'Lecture':'Speak the lines'}`}
        onClose={() => {
          setOpenDialogue(false);
          resetForm();
        }}
        onSubmitClick={handleSubmitUpload}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CustomInput
            id="vdoCipher-title"
            labelText="Video Title"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value: createVdoCipherInputs.title,
              onChange: ev => dispatch(setCreateVdoCipherInputs({ ...createVdoCipherInputs, title: ev.target.value })),
            }}
          />

          <CustomInput
            id="vdoCipher-description"
            labelText="Video Description"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value: createVdoCipherInputs.description,
              onChange: ev =>
                dispatch(setCreateVdoCipherInputs({ ...createVdoCipherInputs, description: ev.target.value })),
            }}
          />

          <TextField
            id="month"
            label="Date"
            type="month"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            value={renderDate()}
            onChange={e =>
              dispatch(setCreateVdoCipherInputs({ ...createVdoCipherInputs, assignedDate: new Date(e.target.value) }))
            }
          />

          <CustomAutocomplete
            labelText="Select Group (optional)"
            multiple
            id="groups"
            placeholder="Groups"
            value={groups.filter(g => createVdoCipherInputs.groups.includes(g.id))}
            onChange={(ev: React.ChangeEvent<{}>, value: OptionType[]) =>
              dispatch(setCreateVdoCipherInputs({ ...createVdoCipherInputs, groups: value.map(item => item.id) }))
            }
            options={groups}
          />

{/*           <Autocomplete
            labelText="Select Type"
            id="type"
            value={videoTypes.filter(v => v.id === createVdoCipherInputs.type)[0]}
            onChange={(ev, value: OptionType) =>
              dispatch(
                setCreateVdoCipherInputs({
                  ...createVdoCipherInputs,
                  type: value.id,
                  subtitles: value.id === 'vdo-lect' ? [] : createVdoCipherInputs.subtitles,
                }),
              )
            }
            options={videoTypes}
          /> */}

          {type === 'vdo-speaking' && <VideoSubtitleInputs />}

          <NavPills tabs={[{
            tabButton: 'upload video',
            tabContent:
          <Button
            style={{ marginTop: 20,marginRight:'auto',marginLeft:'auto' }}
            variant="contained"
            endIcon={<CloudUploadIcon />}
            onClick={() => setOpenDropzone(true)}
          >
              {file ? `Uploaded: ${file.name}` : edit ? `Uploaded: ${createVdoCipherInputs.title}.mp4` : `Upload Video`}
              
            {/* {file ? `Uploaded: ${file.name}` : `Upload Video`} */}
            </Button>
          ,
            tabIcon: VideoLibrary,
          }, {
            tabButton: 'video ID',
            tabContent: <CustomInput
            id="vdoCipher-vdoCipherId"
            labelText="Video vdoCipherId"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value: createVdoCipherInputs.vdoCipherId,
              onChange: ev => dispatch(setCreateVdoCipherInputs({ ...createVdoCipherInputs, vdoCipherId: ev.target.value })),
            }}
            />,
            tabIcon:VideoLibrary
          }]}
            active={0} alignCenter={false} />
         <CustomDropzone
              onClose={() => setOpenDropzone(false)}
              open={openDropzone}
              filesLimit={1}
              initialFiles={[]}
              accept={['video/*']}
              onSave={onSave} />
        </div>
      </ContentSubmitDialog>
    </>
  );
};

export default VideoForm;
