import React from 'react';
import { ColDef, DataGrid, SelectionChangeParams } from '@material-ui/data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from 'features/auth/authSlice';
import { useReqListener } from 'hooks/useReqListener';
import Button from 'components/CustomButtons/Button';
import {
  getStudentTasksAsync,
  getTaskCorrectionDetailsAsync,
  publishGradesForQuizzesAsync,
  reassignQuizzesReqSelector,
  reassignQuizzesToStudentsAsync,
  reassignTakenHomeworks,
  taskCorrectionSelector,
} from '../slices/adminSlice';

import styles from 'assets/jss/lms/features/questions';
import { makeStyles, TextField } from '@material-ui/core';
import { AdminRoutesPrefix } from 'layouts/AdminLayout';
import { RootState } from 'features';
import CardHeader from 'components/Card/CardHeader';
import { useParams } from 'react-router-dom';
import CustomAutocomplete, { OptionType } from 'components/Autocomplete/Autocomplete';
import classNames from 'classnames';
import { downloadStudentReport, getStudentMarkFromAnswerModels, getTotalMarkFromAnswerModels } from 'features/helpers';
import { CloudDownload } from '@material-ui/icons';

const useStyles = makeStyles(styles);

interface Props {}
const CorrectionManagement: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const token = useSelector(tokenSelector);
  const [selectedQuizzesIds, setSelectedQuizzesIds] = React.useState<string[]>([]);
  const reassignQuizzesReq = useSelector(reassignQuizzesReqSelector);
  const publishGradesReq = useSelector((state: RootState) => state.admin.publishGradesReq);
  const [studentNameFilter, setStudentNameFilter] = React.useState<string>('');
  const [studentPhoneFilter, setStudentPhoneFilter] = React.useState<string>('');
  const [parentPhoneFilter, setParentPhoneFilter] = React.useState<string>('');
  const taskCorrectionDetails = useSelector(taskCorrectionSelector).details;
  const taskCorrectionReq = useSelector(taskCorrectionSelector).req;
  const [selectedGroup, setSelectedGroup] = React.useState<OptionType>({ id: '0', name: 'All' });
  const [startDateFilter, setStartDateFilter] = React.useState<Date | undefined>(undefined);
  const [endDateFilter, setEndDateFilter] = React.useState<Date | undefined>(undefined);

  const statusOptions = [
    { name: 'All', id: '0' },
    { name: 'Not Submitted', id: '1' },
    { name: 'Submitted', id: '2' },
  ];
  const [selectedStatus, setSelectedStatus] = React.useState<OptionType>(statusOptions[0]);

  const { taskId } = useParams<{ taskId: string }>();

  const columns: ColDef[] = [
    // { field: 'name', headerName: 'Quiz Name', width: 150 },
    { field: 'student', headerName: 'Student Name', width: 250 },
    { field: 'phone', headerName: 'Student phone', width: 150 },
    { field: 'parentPhone', headerName: 'Parent Phone', width: 150 },
    { field: 'group', headerName: 'Group Name', width: 150 },
    { field: 'status', headerName: 'Status', width: 150 },
    { field: 'assistantReviewed', headerName: 'Reviewed?', width: 125 },
    { field: 'gradePublished', headerName: 'Published?', width: 125 },
    {
      field: 'isLate',
      headerName: 'Late?',
      width: 90,
      //
    },
    {
      field: 'submittedDate',
      headerName: 'Submitted Date',
      width: 220,
      // ,
      // valueFormatter: v => new Date(v.value as string).toUTCString().substring(0, 25),
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      width: 220,
      // ,
      // valueFormatter: v => new Date(v.value as string).toUTCString().substring(0, 25),
    },
    {
      field: 'grade',
      headerName: 'Grade',
      width: 90,
    },
    {
      field: 'correctedBy',
      headerName: 'Corrected By',
      width: 220,
    },
    {
      field: 'action',
      headerName: 'Actions',
      width: 150,
      renderCell: params => (
        <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start' }}>
          <Button
            round
            color="success"
            size="sm"
            fullWidth
            onClick={() => {
              dispatch(getStudentTasksAsync({ studentId: params.row.studentId, token }));
              window.open(`/admin/studentTasks/${params.row.studentId}`);
            }}
          >
            View Progress
          </Button>
        </div>
      ),
    },
  ];

  let rows = taskCorrectionDetails.students.map(q => ({
    id: q.submittedTaskDetails ? q.submittedTaskDetails.id : q.studentDetails.id,
    studentId: q.studentDetails.id,
    student: q.studentDetails.name && q.studentDetails.name,
    phone: q.studentDetails.phone && q.studentDetails.phone,
    parentPhone: q.studentDetails.parentPhone && q.studentDetails.parentPhone,
    group: q.studentDetails.group && q.studentDetails.group.name,
    status: q.submittedTaskDetails ? 'Submitted' : 'Not Submitted',
    assistantReviewed: q.submittedTaskDetails?.instructorGraded && q.submittedTaskDetails.instructorGraded,
    gradePublished: q.submittedTaskDetails?.gradePublished && q.submittedTaskDetails.gradePublished,
    isLate: q.submittedTaskDetails?.isLate && q.submittedTaskDetails.isLate,
    dueDate: q.submittedTaskDetails?.dueDate
      ? new Date(q.submittedTaskDetails.dueDate).toUTCString().substring(0, 25)
      : 'Not Submitted',
    submittedDate: q.submittedTaskDetails?.submittedDate
      ? new Date(q.submittedTaskDetails.submittedDate).toUTCString().substring(0, 25)
      : 'Not Submitted',
    grade: q?.submittedTaskDetails?.answers
      ? getStudentMarkFromAnswerModels(q?.submittedTaskDetails?.answers) +
        '/' +
        getTotalMarkFromAnswerModels(q?.submittedTaskDetails?.answers)
      : '0',
    correctedBy: q.submittedTaskDetails?.correctedBy?.name || '',
  }));

  let filteredQuizes = rows;

  // if (quizNameFilter && quizNameFilter !== '') {
  //   filteredQuizes = filteredQuizes.filter(q => q.name && q.name.toLowerCase().includes(quizNameFilter.toLowerCase()));
  // }
  if (studentNameFilter && studentNameFilter !== '') {
    filteredQuizes = filteredQuizes.filter(q => q.student?.toLowerCase().includes(studentNameFilter.toLowerCase()));
  }
  if (studentPhoneFilter && studentPhoneFilter !== '') {
    filteredQuizes = filteredQuizes.filter(
      q =>
        q && q.phone.toLowerCase().split(' ').join('').includes(studentPhoneFilter.toLowerCase().split(' ').join('')),
    );
  }

  if (startDateFilter) {
    filteredQuizes = filteredQuizes.filter(q => q && q.submittedDate && new Date(q.submittedDate) > startDateFilter);
  }

  if (endDateFilter) {
    filteredQuizes = filteredQuizes.filter(q => q && q.submittedDate && new Date(q.submittedDate) < endDateFilter);
  }
  if (selectedStatus && selectedStatus.name !== 'All') {
    filteredQuizes = filteredQuizes.filter(q => q.status.toLowerCase() === selectedStatus.name.toLowerCase());
  }

  if (selectedGroup && selectedGroup.name !== 'All') {
    filteredQuizes = filteredQuizes.filter(
      q => q.group && q.group?.toLowerCase().includes(selectedGroup.name.toLowerCase()),
    );
  }
  if (parentPhoneFilter && parentPhoneFilter !== '') {
    filteredQuizes = filteredQuizes.filter(
      q =>
        q &&
        q.parentPhone &&
        q.parentPhone.toLowerCase().split(' ').join('').includes(parentPhoneFilter.toLowerCase().split(' ').join('')),
    );
  }

  useReqListener({
    status: publishGradesReq.status,
    successMessage: 'Successfully published the grades for all the selected quizzes',
    loadingMessage: 'Publishing the grades & comments for the selected quizzes',
    errorMessage: publishGradesReq.error,
  });
  useReqListener({
    status: reassignQuizzesReq.status,
    successMessage: 'Successfully reassigned selected quizzes',
    loadingMessage: 'Reassigning selected quizzes',
    errorMessage: reassignQuizzesReq.error,
  });

  React.useEffect(() => {
    if (taskId && dispatch && taskCorrectionReq.status === 'idle') {
      dispatch(getTaskCorrectionDetailsAsync({ id: taskId, token }));
    }
  }, [taskId, dispatch, taskCorrectionReq.status, token]);

  React.useEffect(() => {
    if (taskId && dispatch && reassignQuizzesReq.status === 'loading') {
      dispatch(getTaskCorrectionDetailsAsync({ id: taskId, token }));
    }
  }, [taskId, dispatch, reassignQuizzesReq.status, token]);

  const handleSelectionChange = (a: SelectionChangeParams) => {
    setSelectedQuizzesIds(a.rowIds.map(r => r.toString()));
  };
  const handleQuizTakenCorrectClick = () => {
    selectedQuizzesIds.forEach((qId, index) => {
      const win = window.open(`${AdminRoutesPrefix}/correctQuiz/${qId}`, '_blank');
      if (index === selectedQuizzesIds.length - 1) {
        // if this is the last window to open, make the focus on it
        win?.focus();
      }
    });
  };
  const handlePublishedGradesClick = () => {
    dispatch(publishGradesForQuizzesAsync({ quizzes: selectedQuizzesIds, token }));
    dispatch(reassignTakenHomeworks(selectedQuizzesIds));
  };
  const handleReassignClick = () => {
    const studentsQuizzes = taskCorrectionDetails.students
      .filter(q => q.submittedTaskDetails?.id && selectedQuizzesIds.includes(q.submittedTaskDetails?.id))
      .map(q => ({
        quizId: q.submittedTaskDetails?.id,
        studentId: q.studentDetails.id,
      }));
    dispatch(
      reassignQuizzesToStudentsAsync({
        token,
        studentsQuizzes: studentsQuizzes,
      }),
    );
  };

  const handleDownloadReport = () => {
    const selectedKeys = ['student', 'group', 'isLate', 'submittedDate', 'dueDate', 'grade'];
    let newRows = filteredQuizes.map(obj => {
      const filteredObj = {};
      selectedKeys.forEach(key => {
        filteredObj[key] = obj[key];
      });
      return filteredObj;
    });

    const newColmuns = columns.filter(col => selectedKeys.includes(col.field));
    const sheetName = `${selectedGroup.name}_${taskCorrectionDetails.taskDetails.name}_task`;
    downloadStudentReport(newRows, newColmuns, sheetName.substring(0, 30));
  };

  return (
    <div
      style={{ height: '80vh', width: '100%', display: 'flex', flexDirection: 'column', alignContent: 'flex-start' }}
    >
      <CardHeader
        color="primary"
        className={classes.cardHeader}
        style={{
          marginTop: '20px',
        }}
      >
        <h6 style={{ fontWeight: 'bolder', fontSize: 'large' }}>
          {' '}
          {taskCorrectionDetails.taskDetails.name} task Correction
        </h6>
      </CardHeader>

      <div className={classNames(classes.container, classes.categoryAutocomplete)}>
        <CustomAutocomplete
          value={selectedGroup}
          // multiple
          placeholder="Groups"
          onChange={(event: React.ChangeEvent<{}>, value: OptionType) => setSelectedGroup(value)}
          id="Groups"
          labelText="Groups"
          options={taskCorrectionDetails.taskDetails.groups.concat([{ id: '0', name: 'All' }])}
        />

        <CustomAutocomplete
          value={selectedStatus}
          // multiple
          placeholder="Status"
          onChange={(event: React.ChangeEvent<{}>, value: OptionType) => setSelectedStatus(value)}
          id="Status"
          labelText="Status"
          options={statusOptions}
        />

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
          <Button color="success" round disabled={selectedStatus.id !== '2'} onClick={handleQuizTakenCorrectClick}>
            correct tasks
          </Button>
          <Button color="primary" round disabled={selectedStatus.id !== '2'} onClick={handlePublishedGradesClick}>
            publish grades
          </Button>
          <Button color="danger" round disabled={selectedStatus.id !== '2'} onClick={handleReassignClick}>
            re-assign tasks
          </Button>
        </div>
      </div>

      <div
        className={classNames(classes.container, classes.categoryAutocomplete)}
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
          {/* <TextField
          style={{ marginLeft: '20px' }}
          label="Quiz Name Filter"
          onChange={e => setQuizNameFilter(e.target.value)}
        /> */}
          <TextField
            style={{ marginRight: '20px' }}
            label="Student Name Filter"
            onChange={e => setStudentNameFilter(e.target.value)}
          />
          <TextField
            style={{ marginRight: '20px' }}
            label="Student Phone Filter"
            onChange={e => setStudentPhoneFilter(e.target.value)}
          />
          {/* <TextField
          style={{ marginRight: '20px' }}
          label="Student Group Filter"
          onChange={e => setStudentGroupFilter(e.target.value)}
        /> */}
          <TextField
            style={{ marginRight: '20px' }}
            label="Parent phone Filter"
            onChange={e => setParentPhoneFilter(e.target.value)}
          />

          <TextField
            id="date"
            style={{ marginRight: '20px' }}
            label="From"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e =>
              e.target.value !== '' ? setStartDateFilter(new Date(e.target.value)) : setStartDateFilter(undefined)
            }
          />
          <TextField
            id="date"
            style={{ marginRight: '20px' }}
            label="To"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e =>
              e.target.value !== '' ? setEndDateFilter(new Date(e.target.value)) : setEndDateFilter(undefined)
            }
          />
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button color="success" round onClick={handleDownloadReport}>
            Download Task Report <CloudDownload style={{ marginLeft: '8px' }} className={classes.icons} />
          </Button>
        </div>
      </div>
      <DataGrid
        rows={filteredQuizes}
        pagination
        columns={columns}
        checkboxSelection
        showToolbar
        onSelectionChange={handleSelectionChange}
        loading={taskCorrectionReq.status === 'loading'}
      />
    </div>
  );
};

export default CorrectionManagement;
