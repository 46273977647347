import {
  createHomework,
  createParent,
  createVdoCipher,
  deleteAssistant,
  deleteGroup,
  deleteQuestionCategory,
  deleteQuiz,
  deleteSchool,
  deleteVdoCipher,
  editAssistant,
  editParent,
  editQuestionCategory,
  editQuiz,
  editQuizActivity,
  editVdoCipher,
  getAssignedHomeworks,
  getAssignedQuizzes,
  getSelectedVdoCipher,
  getQuizHomeworkDetails,
  getQuizReport,
  getStudentTasks,
  getTaskCorrectionDetails,
  getVdoCipherVideos,
  uploadUsingCredentials,
  updateStudentsProgressVideo,
  getStudentSpeakingProgress,
} from './../client';
import {
  getAutoCorrectedQuizzes,
  getQuestionCategories,
  getQuestionDiplomas,
  getQuestionGrades,
  getQuestionLevels,
  createQuestionCategory,
  createQuestionDiploma,
  createQuestionGrade,
  createQuestionLevel,
  editQuestionPriority,
  createGroup,
  getQuestions,
  createQuiz,
  createAssistant,
  getStudents,
  getAssistants,
  getSchools,
  getGroups,
  editGroup,
  createStudent,
  editStudent,
  deleteQuestion,
  deleteStudents,
  deleteParent,
  getVimVideos,
  createVimVideo,
  createSchool,
  editVimVideo,
  editTreeNode,
  createTreeView,
  createTreeNode,
  getTreeViews,
  getParents,
} from '../client';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { OptionType } from 'components/Autocomplete/Autocomplete';
import { AdminState, IGroupState } from './slicesTypes';
import { publishGradesForQuizzes, reassignQuizzes } from 'features/quiz/client';
import { addComment, addReply, deleteComment, editComment } from 'features/video/client';

export const activeOptions = [
  { name: 'Not Active', id: 'false' },
  { name: 'Active', id: 'true' },
];

const initialState: AdminState = {
  vimVideos: { vimVideos: [], req: { status: 'idle', error: null } },
  treeViews: { treeViews: [], req: { status: 'idle', error: null } },
  modifyTreeNode: {
    req: { status: 'idle', error: null },
    id: '',
    name: '',
    videos: [],
    children: [],
  },
  createTreeView: { req: { status: 'idle', error: null }, name: '' },
  createTreeNode: { req: { status: 'idle', error: null }, name: '' },
  createSchool: { req: { status: 'idle', error: null }, name: '' },
  createVimVideo: { req: { status: 'idle', error: null }, name: '', url: '' },

  // ========================= Beginning of vdoCipher states ====================================
  vdoCipherVideos: {
    videos: [],
    selectedVideo: undefined,
    req: { status: 'idle', error: null },
  },
  createVdoCipher: {
    req: { status: 'idle', error: null },
    videoInputs: {
      title: '',
      description: '',
      assignedDate: new Date('1970-01-01'),
      type: '',
      subtitles: [],
      groups: [],
    },
  },
  uploadVdoCipher: {
    req: { status: 'idle', error: null },
    credentials: {},
  },
  uploadUsingCredentials: {
    req: { status: 'idle', error: null },
  },
  editVdoCipherReq: { status: 'idle', error: null },
  deleteVdoCipherReq: { status: 'idle', error: null },
  videoCurrentTime: 0,
  isVideoPaused: true,
  threadReq: {
    req: { status: 'idle', error: null },
    operation: null,
    type: null,
  },

  // ========================= End of vdoCipher states ====================================
  students: [],
  parents: [],
  categories: [],
  diplomas: [],
  levels: [],
  grades: [],
  questionRes: {
    questions: [],
    total: 0,
  },
  groups: [],
  assistants: [],
  autoCorrectedQuizzes: { quizzes: [], req: { status: 'idle', error: null }, allLoaded: false },
  assignedHomeworks: { homeworks: [], req: { status: 'idle', error: null }, allLoaded: false },
  assignedQuizzes: { quizzes: [], req: { status: 'idle', error: null }, allLoaded: false },
  schools: [],
  publishGradesReq: { status: 'idle', error: null },
  createStudentReq: { status: 'idle', error: null },
  editStudentReq: { status: 'idle', error: null },
  editParentReq: { status: 'idle', error: null },
  deleteAssistantReq: { status: 'idle', error: null },
  editAssistantReq: { status: 'idle', error: null },
  getSchoolsReq: { status: 'idle', error: null },
  getStudentsReq: { status: 'idle', error: null },
  getParentsReq: { status: 'idle', error: null },
  getCategoriesReq: { status: 'idle', error: null },
  editQuizReq: { status: 'idle', error: null },
  editQuizActivityReq: { status: 'idle', error: null },
  getDiplomasReq: { status: 'idle', error: null },
  getGradesReq: { status: 'idle', error: null },
  getLevelsReq: { status: 'idle', error: null },
  getQuestionsReq: { status: 'idle', error: null },
  deleteQuestionReq: { status: 'idle', error: null },
  getGroupsReq: { status: 'idle', error: null },
  getAssistantsReq: { status: 'idle', error: null },
  createCategory: { req: { status: 'idle', error: null }, name: '' },
  editCategoryReq: { status: 'idle', error: null },
  deleteCategoryReq: { status: 'idle', error: null },
  createDiploma: { req: { status: 'idle', error: null }, name: '' },
  createLevel: { req: { status: 'idle', error: null }, name: '' },
  createGrade: { req: { status: 'idle', error: null }, name: '' },
  deleteStudentReq: { status: 'idle', error: null },
  deleteParentReq: { status: 'idle', error: null },
  deleteSchoolReq: { status: 'idle', error: null },
  deleteQuizReq: { status: 'idle', error: null },
  deleteGroupReq: { status: 'idle', error: null },
  reassignQuizzesReq: { status: 'idle', error: null },
  createQuiz: {
    req: { status: 'idle', error: null },
    name: '',
    questions: [],
    durationInMinutes: 0,
    groups: [],
    active: activeOptions[0],
    dueDate: `${new Date()}`,
  },
  createAssistant: { req: { status: 'idle', error: null }, name: '', email: '', password: '', phone: '' },
  createParent: { req: { status: 'idle', error: null }, name: '', email: '', password: '', phone: '', studentsIds: [] },
  createGroup: {
    req: { status: 'idle', error: null },
    group: {
      name: '',
      assistantsIds: [],
      studentsIds: [],
      schoolId: { id: '600054eb7aaf8b574b79cb50', name: 'MES' },
    },
  },
  modifyGroup: {
    req: { status: 'idle', error: null },
    group: {
      id: '',
      name: '',
      assistants: [],
      students: [],
      school: { id: '600054eb7aaf8b574b79cb50', name: 'MES' },
    },
  },
  modifyVimVideo: {
    req: { status: 'idle', error: null },
    vimVideo: { id: '', name: '', active: false, url: '' },
  },
  assignedTaskDetails: {
    details: {
      studentsStarted: [],
      studentsNotStarted: [],
      studentsSubmitted: [],
      assignedGroups: [],
      taskDetails: { name: '', dueDate: new Date() },
    },
    req: { status: 'idle', error: null },
  },
  taskCorrection: {
    details: {
      // studentsStarted: [],
      // studentsNotStarted: [],
      // studentsSubmitted: [],
      students: [],
      taskDetails: {
        id: '',
        name: '',
        durationInMinutes: 0,
        dueDate: new Date(),
        isHomework: false,
        active: false,
        groups: [],
      },
    },
    req: { status: 'idle', error: null },
  },
  studentTasks: {
    tasks: [],
    studentDetails: {
      id: '',
      name: '',
      email: '',
      phone: '',
      active: false,
      loggedInBefore: false,
      role: 'student',
      parentPhone: '',
      grade: '',
    },
    req: { status: 'idle', error: null },
  },
  studentSpeakingVideoTasks: { speakingVideoTasks: [], req: { status: 'idle', error: null } },
  quizReport: { path: '', req: { status: 'idle', error: null } },
  messages: [],
  notifications: [],
};

// Async updates to the store
export const getQuestionsAsync = createAsyncThunk('admin/getQuestions', getQuestions);
export const getVimVideosAsync = createAsyncThunk('admin/getVimVideos', getVimVideos);
export const getVdoCipherAsync = createAsyncThunk('admin/getVdoCipher', getVdoCipherVideos);
export const getSelectedVdoCipherAsync = createAsyncThunk('admin/getSelectedVdoCipher', getSelectedVdoCipher);
export const getStudentsAsync = createAsyncThunk('admin/getStudents', getStudents);
export const getParentsAsync = createAsyncThunk('admin/getParents', getParents);
export const getCategoriesAsync = createAsyncThunk('admin/getCategories', getQuestionCategories);
export const getGradesAsync = createAsyncThunk('admin/getGrades', getQuestionGrades);
export const getLevelsAsync = createAsyncThunk('admin/getLevels', getQuestionLevels);
export const getDiplomasAsync = createAsyncThunk('admin/getDiplomas', getQuestionDiplomas);
export const getSchoolsAsync = createAsyncThunk('admin/getSchools', getSchools);
export const getAutoCorrectedQuizzesAsync = createAsyncThunk('admin/autoCorrected', getAutoCorrectedQuizzes);
export const getAssignedHomeworksAsync = createAsyncThunk('admin/getHomeworks', getAssignedHomeworks);
export const getAssignedQuizzesAsync = createAsyncThunk('admin/getQuizzes', getAssignedQuizzes);
export const createCategoryAsync = createAsyncThunk('admin/createCategory', createQuestionCategory);
export const editCategoryAsync = createAsyncThunk('admin/editCategory', editQuestionCategory);
export const editQuestionPriorityAsync = createAsyncThunk('admin/editQuestionPriority', editQuestionPriority);
export const deleteCategoryAsync = createAsyncThunk('admin/deleteCategory', deleteQuestionCategory);
export const createDiplomaAsync = createAsyncThunk('admin/createDiploma', createQuestionDiploma);
export const createGradeAsync = createAsyncThunk('admin/createGrade', createQuestionGrade);
export const createLevelAsync = createAsyncThunk('admin/createLevel', createQuestionLevel);
export const createQuizAsync = createAsyncThunk('admin/addQuiz', createQuiz);
export const editQuizAsync = createAsyncThunk('admin/editQuiz', editQuiz);
export const editQuizActivityAsync = createAsyncThunk('admin/editQuizActivity', editQuizActivity);
export const createHomeworkAsync = createAsyncThunk('admin/addHomework', createHomework);
export const createAssistantAsync = createAsyncThunk('admin/addAssistant', createAssistant);
export const createParentAsync = createAsyncThunk('admin/addParent', createParent);
export const getAssistantsAsync = createAsyncThunk('admin/getAssistants', getAssistants);
export const getGroupsAsync = createAsyncThunk('admin/getGroups', getGroups);
export const createGroupAsync = createAsyncThunk('admin/addGroup', createGroup);
export const modifyGroupAsync = createAsyncThunk('admin/modifyGroup', editGroup);
export const publishGradesForQuizzesAsync = createAsyncThunk('admin/publishGrades', publishGradesForQuizzes);
export const reassignQuizzesToStudentsAsync = createAsyncThunk('admin/reassign', reassignQuizzes);
export const createStudentAsync = createAsyncThunk('admin/addStudent', createStudent);
export const createSchoolAsync = createAsyncThunk('admin/addSchool', createSchool);
export const createVimVideoAsync = createAsyncThunk('admin/addVimVideo', createVimVideo);
export const editStudentAsync = createAsyncThunk('admin/editStudent', editStudent);
export const editParentAsync = createAsyncThunk('admin/editParent', editParent);
export const editAssistantAsync = createAsyncThunk('admin/editAssistant', editAssistant);
export const deleteQuestionAsync = createAsyncThunk('admin/deleteQuestion', deleteQuestion);
export const deleteStudentsAsync = createAsyncThunk('admin/deleteStudent', deleteStudents);
export const deleteParentAsync = createAsyncThunk('admin/deleteParent', deleteParent);
export const deleteAssistantAsync = createAsyncThunk('admin/deleteAssistant', deleteAssistant);
export const deleteGroupAsync = createAsyncThunk('admin/deleteGroup', deleteGroup);
export const deleteSchoolAsync = createAsyncThunk('admin/deleteSchool', deleteSchool);
export const deleteQuizAsync = createAsyncThunk('admin/deleteQuiz', deleteQuiz);
export const modifyVimVideoAsync = createAsyncThunk('admin/editVimVideo', editVimVideo);

export const createVdoCipherAsync = createAsyncThunk('admin/createVdoCipher', createVdoCipher);
export const editVdoCipherAsync = createAsyncThunk('admin/editVdoCipher', editVdoCipher);
export const deleteVdoCipherAsync = createAsyncThunk('admin/deleteVdoCipher', deleteVdoCipher);
export const uploadUsingCredentialsAsync = createAsyncThunk('admin/uploadUsingCredentials', uploadUsingCredentials);

export const updateStudentsProgressVideoAsync = createAsyncThunk(
  'videoStudentProgress/addComment',
  updateStudentsProgressVideo,
);

export const addCommentAsync = createAsyncThunk('thread/addComment', addComment);
export const addReplyAsync = createAsyncThunk('thread/addReply', addReply);
export const editCommentAsync = createAsyncThunk('thread/editComment', editComment);
export const deleteCommentAsync = createAsyncThunk('thread/deleteComment', deleteComment);

export const getTreeViewsAsync = createAsyncThunk('admin/getTreeViews', getTreeViews);
export const createTreeViewAsync = createAsyncThunk('admin/addTreeView', createTreeView);
export const createTreeNodeAsync = createAsyncThunk('admin/addTreeNode', createTreeNode);
export const modifyTreeNodeAsync = createAsyncThunk('admin/editTreeNode', editTreeNode);
export const getQuizHomeworkDetailsAsync = createAsyncThunk('admin/getQuizHomeworkDetails', getQuizHomeworkDetails);
export const getTaskCorrectionDetailsAsync = createAsyncThunk(
  'admin/getTaskCorrectionDetails',
  getTaskCorrectionDetails,
);
export const getStudentTasksAsync = createAsyncThunk('admin/getStudentTasks', getStudentTasks);
export const getStudentSpeakingProgressAsync = createAsyncThunk(
  'admin/getStudentSpeakingProgress',
  getStudentSpeakingProgress,
);

export const getQuizReportAsync = createAsyncThunk('admin/getQuizReport', getQuizReport);
export const slice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setCreateGroupName: (state, action: PayloadAction<{ name: string }>) => {
      state.createGroup.group.name = action.payload.name;
    },
    setCreateGroupStudents: (state, action: PayloadAction<Types.IOptionType[]>) => {
      state.createGroup.group.studentsIds = action.payload;
    },

    setCreateGroupAssistants: (state, action: PayloadAction<Types.IOptionType[]>) => {
      state.createGroup.group.assistantsIds = action.payload;
    },
    setCreateGroupSchool: (state, action: PayloadAction<Types.IOptionType>) => {
      state.createGroup.group.schoolId = action.payload;
    },
    setSchoolName: (state, action: PayloadAction<{ name: string }>) => {
      state.createSchool.name = action.payload.name;
    },
    setVimVideoName: (state, action: PayloadAction<{ name: string }>) => {
      state.createVimVideo.name = action.payload.name;
    },
    setTreeViewName: (state, action: PayloadAction<{ name: string }>) => {
      state.createTreeView.name = action.payload.name;
    },
    setVimVideoUrl: (state, action: PayloadAction<{ url: string }>) => {
      state.createVimVideo.url = action.payload.url;
    },
    setCreateVdoCipherInputs: (state, action: PayloadAction<Types.API.VdoCipher.IVdoCipherInputs>) => {
      state.createVdoCipher.videoInputs = action.payload;
    },
    setCreateCategoryName: (state, action: PayloadAction<{ name: string }>) => {
      state.createCategory.name = action.payload.name;
    },
    setCreateDiplomaName: (state, action: PayloadAction<{ name: string }>) => {
      state.createDiploma.name = action.payload.name;
    },
    setCreateLevelName: (state, action: PayloadAction<{ name: string }>) => {
      state.createLevel.name = action.payload.name;
    },
    setCreateGradeName: (state, action: PayloadAction<{ name: string }>) => {
      state.createGrade.name = action.payload.name;
    },
    setCreateQuizName: (state, action: PayloadAction<{ name: string }>) => {
      state.createQuiz.name = action.payload.name;
    },
    setCreateQuizActivity: (state, action: PayloadAction<{ active: OptionType }>) => {
      state.createQuiz.active = action.payload.active;
    },
    setCreateQuizTime: (state, action: PayloadAction<{ durationInMinutes: number }>) => {
      state.createQuiz.durationInMinutes = action.payload.durationInMinutes;
    },
    setCreateQuizDueDate: (state, action: PayloadAction<{ dueDate: Date }>) => {
      state.createQuiz.dueDate = `${action.payload.dueDate}`;
    },
    setCreateQuizGroups: (state, action: PayloadAction<{ groups: OptionType[] }>) => {
      state.createQuiz.groups = action.payload.groups;
    },
    setCreateQuizSelection: (state, action: PayloadAction<{ questions: string[] }>) => {
      state.createQuiz.questions = action.payload.questions;
    },
    setCreateAssistantName: (state, action: PayloadAction<{ name: string }>) => {
      state.createAssistant.name = action.payload.name;
    },
    setCreateAssistantEmail: (state, action: PayloadAction<{ email: string }>) => {
      state.createAssistant.email = action.payload.email;
    },
    setCreateAssistantPhone: (state, action: PayloadAction<{ phone: string }>) => {
      state.createAssistant.phone = action.payload.phone;
    },
    setCreateAssistantPassword: (state, action: PayloadAction<{ password: string }>) => {
      state.createAssistant.password = action.payload.password;
    },
    setCreateParentName: (state, action: PayloadAction<{ name: string }>) => {
      state.createParent.name = action.payload.name;
    },
    setCreateParentEmail: (state, action: PayloadAction<{ email: string }>) => {
      state.createParent.email = action.payload.email;
    },
    setCreateParentPhone: (state, action: PayloadAction<{ phone: string }>) => {
      state.createParent.phone = action.payload.phone;
    },
    setCreateParentPassword: (state, action: PayloadAction<{ password: string }>) => {
      state.createParent.password = action.payload.password;
    },
    setCreateParentStudentsIds: (state, action: PayloadAction<{ studentsIds: Types.IOptionType[] }>) => {
      state.createParent.studentsIds = action.payload.studentsIds;
    },
    resetModifyGroup: (state, action: PayloadAction) => {
      state.modifyGroup.group = initialState.modifyGroup.group;
    },
    setModifyGroup: (state, action: PayloadAction<IGroupState | undefined>) => {
      if (action.payload) state.modifyGroup.group = action.payload;
    },
    setModifyGroupName: (state, action: PayloadAction<string>) => {
      if (action.payload) state.modifyGroup.group.name = action.payload;
    },
    setModifyGroupStudents: (state, action: PayloadAction<Types.IOptionType[]>) => {
      state.modifyGroup.group.students = action.payload;
    },
    setModifyGroupAssistants: (state, action: PayloadAction<Types.IOptionType[]>) => {
      state.modifyGroup.group.assistants = action.payload;
    },
    setModifyVimVideo: (state, action: PayloadAction<Types.API.Video.IVideoInfo | undefined>) => {
      if (action.payload) state.modifyVimVideo.vimVideo = { ...action.payload };
    },
    setModifyVimVideoActive: (state, action: PayloadAction<boolean>) => {
      state.modifyVimVideo.vimVideo.active = action.payload;
    },
    setModifyVimVideoName: (state, action: PayloadAction<string>) => {
      state.modifyVimVideo.vimVideo.name = action.payload;
    },
    setModifyVimVideoUrl: (state, action: PayloadAction<string>) => {
      state.modifyVimVideo.vimVideo.url = action.payload;
    },
    reassignTakenHomeworks: (state, action: PayloadAction<string[]>) => {
      state.autoCorrectedQuizzes.quizzes = state.autoCorrectedQuizzes.quizzes.filter(
        q => !action.payload.includes(q.id),
      );
    },
    setQuizReviewedState: (state, action: PayloadAction<{ quizId: string; reviewed: boolean }>) => {
      state.autoCorrectedQuizzes.quizzes.map(quiz => {
        if (quiz.id.toString() == action.payload.quizId.toString()) {
          quiz.instructorGraded = action.payload.reviewed;
        }
      });
    },
    setVideoCurrentTime: (state, action: PayloadAction<string | number>) => {
      let updatedCurrentTime = 0;
      if (typeof action.payload === 'number') updatedCurrentTime = action.payload;

      if (typeof action.payload === 'string') {
        const [hour, minute, second] = action.payload.split(':') as [string, string, string];
        updatedCurrentTime = parseInt(hour) * 60 * 60 + parseInt(minute) * 60 + parseInt(second);
      }
      state.videoCurrentTime = updatedCurrentTime;
    },
    setIsVideoPaused: (state, action: PayloadAction<boolean>) => {
      state.isVideoPaused = action.payload;
    },
  },
  extraReducers: builder => {
    // Async updates to the store listeners

    builder.addCase(createTreeNodeAsync.pending, (state, action) => {
      state.createTreeNode.req.status = 'loading';
    });
    builder.addCase(createTreeNodeAsync.fulfilled, (state, action) => {
      state.createTreeNode.req.status = 'succeeded';
      // Change the getSchools status
      state.treeViews.req.status = 'idle';
    });
    builder.addCase(createTreeNodeAsync.rejected, (state, action) => {
      state.createTreeNode.req.status = 'failed';
      state.createTreeNode.req.error = action.error.message || 'Unknown error';
    });

    builder.addCase(modifyTreeNodeAsync.pending, (state, action) => {
      state.modifyTreeNode.req.status = 'loading';
    });
    builder.addCase(modifyTreeNodeAsync.fulfilled, (state, action) => {
      state.modifyTreeNode.req.status = 'succeeded';
      // Change the getVideos status
      state.treeViews.req.status = 'idle';
    });
    builder.addCase(modifyTreeNodeAsync.rejected, (state, action) => {
      state.modifyTreeNode.req.status = 'failed';
      state.modifyTreeNode.req.error = action.error.message || 'Unknown error';
    });

    builder.addCase(editQuizAsync.pending, (state, action) => {
      state.editQuizReq.status = 'loading';
    });
    builder.addCase(editQuizAsync.fulfilled, (state, action) => {
      state.editQuizReq.status = 'succeeded';
      const hwIndex = state.assignedHomeworks.homeworks.findIndex(h => h._id === action.payload._id);
      const qIndex = state.assignedQuizzes.quizzes.findIndex(q => q._id === action.payload._id);
      if (hwIndex > -1) state.assignedHomeworks.homeworks[hwIndex] = action.payload;
      if (qIndex > -1) state.assignedQuizzes.quizzes[qIndex] = action.payload;
    });
    builder.addCase(editQuizAsync.rejected, (state, action) => {
      state.editQuizReq.status = 'failed';
      state.editQuizReq.error = action.error.message || 'Unknown error';
    });

    builder.addCase(editQuizActivityAsync.pending, (state, action) => {
      state.editQuizActivityReq.status = 'loading';
    });
    builder.addCase(editQuizActivityAsync.fulfilled, (state, action) => {
      state.editQuizActivityReq.status = 'succeeded';
      // const hwIndex = state.assignedHomeworks.homeworks.findIndex(h => h._id === action.payload._id);
      // const qIndex = state.assignedQuizzes.quizzes.findIndex(q => q._id === action.payload._id);
      // if (hwIndex > -1) state.assignedHomeworks.homeworks[hwIndex] = action.payload;
      // if (qIndex > -1) state.assignedQuizzes.quizzes[qIndex] = action.payload;
    });
    builder.addCase(editQuizActivityAsync.rejected, (state, action) => {
      state.editQuizActivityReq.status = 'failed';
      state.editQuizActivityReq.error = action.error.message || 'Unknown error';
    });

    builder.addCase(createTreeViewAsync.pending, (state, action) => {
      state.createTreeView.req.status = 'loading';
    });
    builder.addCase(createTreeViewAsync.fulfilled, (state, action) => {
      state.createTreeView.req.status = 'succeeded';
      // Change the getSchools status
      state.treeViews.req.status = 'idle';
    });
    builder.addCase(createTreeViewAsync.rejected, (state, action) => {
      state.createTreeView.req.status = 'failed';
      state.createTreeView.req.error = action.error.message || 'Unknown error';
    });

    builder.addCase(getTreeViewsAsync.pending, (state, action) => {
      state.treeViews.req.status = 'loading';
    });
    builder.addCase(getTreeViewsAsync.fulfilled, (state, action) => {
      state.treeViews.req.status = 'succeeded';
      // Change the getSchools status
      state.treeViews.treeViews = action.payload;
    });
    builder.addCase(getTreeViewsAsync.rejected, (state, action) => {
      state.treeViews.req.status = 'failed';
      state.treeViews.req.error = action.error.message || 'Unknown error';
    });

    builder.addCase(modifyVimVideoAsync.pending, (state, action) => {
      state.modifyVimVideo.req.status = 'loading';
    });
    builder.addCase(modifyVimVideoAsync.fulfilled, (state, action) => {
      state.modifyVimVideo.req.status = 'succeeded';
      // Change the getVideos status
      state.vimVideos.req.status = 'idle';
    });
    builder.addCase(modifyVimVideoAsync.rejected, (state, action) => {
      state.modifyVimVideo.req.status = 'failed';
      state.modifyVimVideo.req.error = action.error.message || 'Unknown error';
    });

    builder.addCase(createVdoCipherAsync.pending, (state, action) => {
      state.uploadVdoCipher.req.status = 'loading';

      state.createVdoCipher.req.status = 'loading';
    });
    builder.addCase(createVdoCipherAsync.fulfilled, (state, action) => {
      state.uploadVdoCipher.req.status = 'succeeded';
      state.uploadVdoCipher.credentials = action.payload;

      state.createVdoCipher.req.status = 'succeeded';
    });
    builder.addCase(createVdoCipherAsync.rejected, (state, action) => {
      state.uploadVdoCipher.req.status = 'failed';
      state.uploadVdoCipher.req.error = action.error.message || 'Unknown error';

      state.createVdoCipher.req.status = 'failed';
      state.createVdoCipher.req.error = action.error.message || 'Unknown error';
    });

    builder.addCase(editVdoCipherAsync.pending, (state, action) => {
      state.editVdoCipherReq.status = 'loading';
    });
    builder.addCase(editVdoCipherAsync.fulfilled, (state, action) => {
      state.editVdoCipherReq.status = 'succeeded';
      state.uploadVdoCipher.credentials = action.payload.credential;
    });
    builder.addCase(editVdoCipherAsync.rejected, (state, action) => {
      state.editVdoCipherReq.status = 'failed';
      state.editVdoCipherReq.error = action.error.message || 'Unknown error';
    });

    builder.addCase(deleteVdoCipherAsync.pending, (state, action) => {
      state.deleteVdoCipherReq.status = 'loading';
    });
    builder.addCase(deleteVdoCipherAsync.fulfilled, (state, action) => {
      state.deleteVdoCipherReq.status = 'succeeded';
    });
    builder.addCase(deleteVdoCipherAsync.rejected, (state, action) => {
      state.deleteVdoCipherReq.status = 'failed';
      state.deleteVdoCipherReq.error = action.error.message || 'Unknown error';
    });

    builder.addCase(uploadUsingCredentialsAsync.pending, (state, action) => {
      state.uploadUsingCredentials.req.status = 'loading';
    });
    builder.addCase(uploadUsingCredentialsAsync.fulfilled, (state, action) => {
      state.uploadUsingCredentials.req.status = 'succeeded';
    });
    builder.addCase(uploadUsingCredentialsAsync.rejected, (state, action) => {
      state.uploadUsingCredentials.req.status = 'failed';
      state.uploadUsingCredentials.req.error = action.error.message || 'Unknown error';
    });

    builder.addCase(addCommentAsync.pending, (state, action) => {
      state.threadReq.req.status = 'loading';
      state.threadReq.operation = 'create';
      state.threadReq.type = 'comment';
    });
    builder.addCase(addCommentAsync.fulfilled, (state, action) => {
      state.threadReq.req.status = 'succeeded';
      state.threadReq.operation = 'create';
      state.threadReq.type = 'comment';
    });
    builder.addCase(addCommentAsync.rejected, (state, action) => {
      state.threadReq.req.status = 'failed';
      state.threadReq.operation = 'create';
      state.threadReq.type = 'comment';
      state.threadReq.req.error = action.error.message || 'Unknown error';
    });

    builder.addCase(addReplyAsync.pending, (state, action) => {
      state.threadReq.req.status = 'loading';
      state.threadReq.operation = 'create';
      state.threadReq.type = 'reply';
    });
    builder.addCase(addReplyAsync.fulfilled, (state, action) => {
      state.threadReq.req.status = 'succeeded';
      state.threadReq.operation = 'create';
      state.threadReq.type = 'reply';
    });
    builder.addCase(addReplyAsync.rejected, (state, action) => {
      state.threadReq.req.status = 'failed';
      state.threadReq.operation = 'create';
      state.threadReq.type = 'reply';
      state.threadReq.req.error = action.error.message || 'Unknown error';
    });

    builder.addCase(editCommentAsync.pending, (state, action) => {
      state.threadReq.req.status = 'loading';
      state.threadReq.operation = 'update';
    });
    builder.addCase(editCommentAsync.fulfilled, (state, action) => {
      state.threadReq.req.status = 'succeeded';
      state.threadReq.operation = 'update';
    });
    builder.addCase(editCommentAsync.rejected, (state, action) => {
      state.threadReq.req.status = 'failed';
      state.threadReq.operation = 'update';
      state.threadReq.req.error = action.error.message || 'Unknown error';
    });

    builder.addCase(deleteCommentAsync.pending, (state, action) => {
      state.threadReq.req.status = 'loading';
      state.threadReq.operation = 'delete';
    });
    builder.addCase(deleteCommentAsync.fulfilled, (state, action) => {
      state.threadReq.req.status = 'succeeded';
      state.threadReq.operation = 'delete';
    });
    builder.addCase(deleteCommentAsync.rejected, (state, action) => {
      state.threadReq.req.status = 'failed';
      state.threadReq.operation = 'delete';
      state.threadReq.req.error = action.error.message || 'Unknown error';
    });

    builder.addCase(createSchoolAsync.pending, (state, action) => {
      state.createSchool.req.status = 'loading';
    });
    builder.addCase(createSchoolAsync.fulfilled, (state, action) => {
      state.createSchool.req.status = 'succeeded';
      // Change the getSchools status
      state.getSchoolsReq.status = 'idle';
    });
    builder.addCase(createSchoolAsync.rejected, (state, action) => {
      state.createSchool.req.status = 'failed';
      state.createSchool.req.error = action.error.message || 'Unknown error';
    });

    builder.addCase(createVimVideoAsync.pending, (state, action) => {
      state.createVimVideo.req.status = 'loading';
    });
    builder.addCase(createVimVideoAsync.fulfilled, (state, action) => {
      state.createVimVideo.req.status = 'succeeded';
      // Change the getSchools status
      state.vimVideos.req.status = 'idle';
    });
    builder.addCase(createVimVideoAsync.rejected, (state, action) => {
      state.createVimVideo.req.status = 'failed';
      state.createVimVideo.req.error = action.error.message || 'Unknown error';
    });

    builder.addCase(getVimVideosAsync.pending, (state, action) => {
      state.vimVideos.req.status = 'loading';
    });
    builder.addCase(getVimVideosAsync.fulfilled, (state, action) => {
      state.vimVideos.req.status = 'succeeded';
      // Change the getSchools status
      state.vimVideos.vimVideos = action.payload;
    });
    builder.addCase(getVimVideosAsync.rejected, (state, action) => {
      state.vimVideos.req.status = 'failed';
      state.vimVideos.req.error = action.error.message || 'Unknown error';
    });

    builder.addCase(getVdoCipherAsync.pending, (state, action) => {
      state.vdoCipherVideos.req.status = 'loading';
    });
    builder.addCase(getVdoCipherAsync.fulfilled, (state, action) => {
      state.vdoCipherVideos.req.status = 'succeeded';
      state.vdoCipherVideos.videos = action.payload;
    });
    builder.addCase(getVdoCipherAsync.rejected, (state, action) => {
      state.vdoCipherVideos.req.status = 'failed';
      state.vdoCipherVideos.req.error = action.error.message || 'Unknown error';
    });

    builder.addCase(getSelectedVdoCipherAsync.pending, (state, action) => {
      state.vdoCipherVideos.req.status = 'loading';
    });
    builder.addCase(getSelectedVdoCipherAsync.fulfilled, (state, action) => {
      state.vdoCipherVideos.req.status = 'succeeded';
      state.vdoCipherVideos.selectedVideo = action.payload;
    });
    builder.addCase(getSelectedVdoCipherAsync.rejected, (state, action) => {
      state.vdoCipherVideos.req.status = 'failed';
      state.vdoCipherVideos.req.error = action.error.message || 'Unknown error';
    });

    builder.addCase(getStudentsAsync.pending, (state, action) => {
      state.getStudentsReq.status = 'loading';
    });
    builder.addCase(getStudentsAsync.fulfilled, (state, action) => {
      state.getStudentsReq.status = 'succeeded';
      state.students = action.payload;
    });
    builder.addCase(getStudentsAsync.rejected, (state, action) => {
      state.getStudentsReq.status = 'failed';
      state.getStudentsReq.error = action.error.message || 'Unknown error';
    });
    builder.addCase(getParentsAsync.pending, (state, action) => {
      state.getParentsReq.status = 'loading';
    });
    builder.addCase(getParentsAsync.fulfilled, (state, action) => {
      state.getParentsReq.status = 'succeeded';
      state.parents = action.payload;
    });
    builder.addCase(getParentsAsync.rejected, (state, action) => {
      state.getParentsReq.status = 'failed';
      state.getParentsReq.error = action.error.message || 'Unknown error';
    });
    builder.addCase(getQuestionsAsync.pending, (state, action) => {
      state.getQuestionsReq.status = 'loading';
    });
    builder.addCase(
      getQuestionsAsync.fulfilled,
      (state, action: PayloadAction<{ questions: Types.API.Question.IQuestionModel[]; total: number }>) => {
        state.getQuestionsReq.status = 'succeeded';
        state.questionRes.total = action.payload.total;
        state.questionRes.questions = action.payload.questions.map(qu => ({
          id: qu._id,
          title: qu.title,
          priority: qu.priority,
          // priority: qu.priority || (qu.category && `${qu.category.name}#`),
          diplomaType: qu.diploma && qu.diploma.name,
          gradeType: qu.grade && qu.grade.name,
          levelType: qu.level && qu.level.name,
          categoryType: qu.category && qu.category.name,
          selected: false,
        }));
      },
    );
    builder.addCase(getQuestionsAsync.rejected, (state, action) => {
      state.getQuestionsReq.status = 'failed';
      state.getQuestionsReq.error = action.error.message || 'Unknown error';
    });

    builder.addCase(createCategoryAsync.fulfilled, (state, action) => {
      state.categories.push(action.payload);
      state.createCategory.name = '';
      state.createCategory.req.status = 'succeeded';
    });
    builder.addCase(createCategoryAsync.rejected, (state, action) => {
      state.createCategory.req.status = 'failed';
      state.createCategory.req.error = action.error.message || 'Unknown error';
    });
    builder.addCase(createCategoryAsync.pending, (state, action) => {
      state.createCategory.req.status = 'loading';
    });

    builder.addCase(editCategoryAsync.fulfilled, (state, action) => {
      state.editCategoryReq.status = 'succeeded';
      state.getCategoriesReq.status = 'idle';
    });
    builder.addCase(editCategoryAsync.rejected, (state, action) => {
      state.editCategoryReq.status = 'failed';
      state.editCategoryReq.error = action.error.message || 'Unknown error';
    });
    builder.addCase(editCategoryAsync.pending, (state, action) => {
      state.editCategoryReq.status = 'loading';
    });

    builder.addCase(deleteCategoryAsync.fulfilled, (state, action) => {
      state.deleteCategoryReq.status = 'succeeded';
      state.getCategoriesReq.status = 'idle';
    });
    builder.addCase(deleteCategoryAsync.rejected, (state, action) => {
      state.deleteCategoryReq.status = 'failed';
      state.deleteCategoryReq.error = action.error.message || 'Unknown error';
    });
    builder.addCase(deleteCategoryAsync.pending, (state, action) => {
      state.deleteCategoryReq.status = 'loading';
    });

    builder.addCase(createDiplomaAsync.fulfilled, (state, action) => {
      state.diplomas.push(action.payload);
      state.createDiploma.name = '';
      state.createDiploma.req.status = 'succeeded';
    });
    builder.addCase(createGradeAsync.fulfilled, (state, action) => {
      state.grades.push(action.payload);
      state.createGrade.name = '';
      state.createGrade.req.status = 'succeeded';
    });
    builder.addCase(createLevelAsync.fulfilled, (state, action) => {
      state.levels.push(action.payload);
      state.createLevel.name = '';
      state.createLevel.req.status = 'succeeded';
    });
    builder.addCase(getCategoriesAsync.fulfilled, (state, action) => {
      state.categories = action.payload;
      state.getCategoriesReq.status = 'succeeded';
    });
    builder.addCase(getAssistantsAsync.fulfilled, (state, action) => {
      state.assistants = action.payload;
      state.getAssistantsReq.status = 'succeeded';
    });
    builder.addCase(getAutoCorrectedQuizzesAsync.rejected, (state, action) => {
      state.autoCorrectedQuizzes.req.status = 'failed';
    });
    builder.addCase(getAutoCorrectedQuizzesAsync.pending, (state, action) => {
      state.autoCorrectedQuizzes.req.status = 'loading';
    });
    builder.addCase(getAutoCorrectedQuizzesAsync.fulfilled, (state, action) => {
      const updatedQuizzes = [...state.autoCorrectedQuizzes.quizzes];
      action.payload.quizzes.map(q => {
        if (!updatedQuizzes.some(included => included.id === q.id)) updatedQuizzes.push(q);
      });
      state.autoCorrectedQuizzes.quizzes = updatedQuizzes;
      state.autoCorrectedQuizzes.req.status = 'succeeded';
      console.log(action.meta.arg.limit, action.payload.quizzes.length);
      if (action.payload.quizzes.length < action.meta.arg.limit) state.autoCorrectedQuizzes.allLoaded = true;
    });
    builder.addCase(getAssignedHomeworksAsync.rejected, (state, action) => {
      state.assignedHomeworks.req.status = 'failed';
    });
    builder.addCase(getAssignedHomeworksAsync.pending, (state, action) => {
      state.assignedHomeworks.req.status = 'loading';
    });
    builder.addCase(getAssignedHomeworksAsync.fulfilled, (state, action) => {
      const assignedHomeworks = [...state.assignedHomeworks.homeworks];
      action.payload.homeworks.map(q => {
        if (!assignedHomeworks.some(included => included._id === q._id)) assignedHomeworks.push(q);
      });
      state.assignedHomeworks.homeworks = assignedHomeworks;
      state.assignedHomeworks.req.status = 'succeeded';
      if (action.payload.homeworks.length < action.meta.arg.limit) state.assignedHomeworks.allLoaded = true;
    });
    builder.addCase(getAssignedQuizzesAsync.rejected, (state, action) => {
      state.assignedQuizzes.req.status = 'failed';
    });
    builder.addCase(getAssignedQuizzesAsync.pending, (state, action) => {
      state.assignedQuizzes.req.status = 'loading';
    });
    builder.addCase(getAssignedQuizzesAsync.fulfilled, (state, action) => {
      const assignedQuizzes = [...state.assignedQuizzes.quizzes];
      action.payload.quizzes.map(q => {
        if (!assignedQuizzes.some(included => included._id === q._id)) assignedQuizzes.push(q);
      });
      state.assignedQuizzes.quizzes = assignedQuizzes;
      state.assignedQuizzes.req.status = 'succeeded';
      if (action.payload.quizzes.length < action.meta.arg.limit) state.assignedQuizzes.allLoaded = true;
    });
    builder.addCase(createGroupAsync.rejected, (state, action) => {
      state.createGroup.req.status = 'failed';
    });
    builder.addCase(createGroupAsync.pending, (state, action) => {
      state.createGroup.req.status = 'loading';
    });
    builder.addCase(createGroupAsync.fulfilled, (state, action) => {
      state.createGroup.group.name = '';
      state.createGroup.group.schoolId = state.schools[0];
      state.createGroup.group.studentsIds = [];
      state.createGroup.group.assistantsIds = [];
      state.createGroup.req.status = 'succeeded';
      state.getGroupsReq.status = 'idle';
    });
    builder.addCase(modifyGroupAsync.rejected, (state, action) => {
      state.modifyGroup.req.status = 'failed';
    });
    builder.addCase(modifyGroupAsync.pending, (state, action) => {
      state.modifyGroup.req.status = 'loading';
    });
    builder.addCase(modifyGroupAsync.fulfilled, (state, action) => {
      state.modifyGroup.req.status = 'succeeded';
      state.modifyGroup.group.name = '';
      state.modifyGroup.group.school = state.schools[0];
      state.modifyGroup.group.assistants = [];
      state.modifyGroup.group.students = [];
      state.getGroupsReq.status = 'idle';
    });
    builder.addCase(getGroupsAsync.fulfilled, (state, action) => {
      state.groups = action.payload;
      state.getGroupsReq.status = 'succeeded';
    });
    builder.addCase(getGradesAsync.fulfilled, (state, action) => {
      state.grades = action.payload;
      state.getGradesReq.status = 'succeeded';
    });
    builder.addCase(getLevelsAsync.fulfilled, (state, action) => {
      state.levels = action.payload;
      state.getLevelsReq.status = 'succeeded';
    });
    builder.addCase(getDiplomasAsync.fulfilled, (state, action) => {
      state.diplomas = action.payload;
      state.getDiplomasReq.status = 'succeeded';
    });
    builder.addCase(getSchoolsAsync.fulfilled, (state, action) => {
      state.schools = action.payload;
      state.getSchoolsReq.status = 'succeeded';
    });
    builder.addCase(publishGradesForQuizzesAsync.fulfilled, (state, action) => {
      state.publishGradesReq.status = 'succeeded';
    });
    builder.addCase(publishGradesForQuizzesAsync.pending, (state, action) => {
      state.publishGradesReq.status = 'loading';
    });
    builder.addCase(publishGradesForQuizzesAsync.rejected, (state, action) => {
      state.publishGradesReq.status = 'failed';
      state.publishGradesReq.error = action.error.message || 'Unknown error';
    });
    builder.addCase(reassignQuizzesToStudentsAsync.fulfilled, (state, action) => {
      state.reassignQuizzesReq.status = 'succeeded';
      // const reassignedIds = action.meta.arg.studentsQuizzes.map(q => q.quizId);
      // state.autoCorrectedQuizzes.quizzes = state.autoCorrectedQuizzes.quizzes.filter(
      //   q => !reassignedIds.includes(q.id),
      // );
    });
    builder.addCase(reassignQuizzesToStudentsAsync.pending, (state, action) => {
      state.reassignQuizzesReq.status = 'loading';
    });
    builder.addCase(reassignQuizzesToStudentsAsync.rejected, (state, action) => {
      state.reassignQuizzesReq.status = 'failed';
      state.reassignQuizzesReq.error = action.error.message || 'Unknown error';
    });
    builder.addCase(createStudentAsync.fulfilled, (state, action) => {
      state.createStudentReq.status = 'succeeded';
      state.getStudentsReq.status = 'idle';
      window.location.reload();
    });
    builder.addCase(editStudentAsync.fulfilled, (state, action) => {
      state.editStudentReq.status = 'succeeded';
      state.getStudentsReq.status = 'idle';
    });
    builder.addCase(editParentAsync.fulfilled, (state, action) => {
      state.editParentReq.status = 'succeeded';
    });
    builder.addCase(deleteQuestionAsync.pending, (state, action) => {
      state.deleteQuestionReq.status = 'loading';
    });
    builder.addCase(deleteQuestionAsync.rejected, (state, action) => {
      state.deleteQuestionReq.status = 'failed';
    });
    builder.addCase(deleteQuestionAsync.fulfilled, (state, action) => {
      state.deleteQuestionReq.status = 'succeeded';
      state.getQuestionsReq.status = 'idle';
    });
    builder.addCase(deleteStudentsAsync.pending, (state, action) => {
      state.deleteStudentReq.status = 'loading';
    });
    builder.addCase(deleteStudentsAsync.rejected, (state, action) => {
      state.deleteStudentReq.status = 'failed';
    });
    builder.addCase(deleteStudentsAsync.fulfilled, (state, action) => {
      state.deleteStudentReq.status = 'succeeded';
      state.getStudentsReq.status = 'idle';
    });
    builder.addCase(deleteParentAsync.pending, (state, action) => {
      state.deleteParentReq.status = 'loading';
    });
    builder.addCase(deleteParentAsync.rejected, (state, action) => {
      state.deleteParentReq.status = 'failed';
    });
    builder.addCase(deleteParentAsync.fulfilled, (state, action) => {
      state.deleteParentReq.status = 'succeeded';
      state.getParentsReq.status = 'idle';
    });
    builder.addCase(deleteSchoolAsync.pending, (state, action) => {
      state.deleteSchoolReq.status = 'loading';
    });
    builder.addCase(deleteSchoolAsync.rejected, (state, action) => {
      state.deleteSchoolReq.status = 'failed';
    });
    builder.addCase(deleteSchoolAsync.fulfilled, (state, action) => {
      state.deleteSchoolReq.status = 'succeeded';
      state.getSchoolsReq.status = 'idle';
    });
    builder.addCase(deleteQuizAsync.pending, (state, action) => {
      state.deleteQuizReq.status = 'loading';
    });
    builder.addCase(deleteQuizAsync.rejected, (state, action) => {
      state.deleteQuizReq.status = 'failed';
    });
    builder.addCase(deleteQuizAsync.fulfilled, (state, action) => {
      state.deleteQuizReq.status = 'succeeded';
      state.assignedHomeworks.homeworks = state.assignedHomeworks.homeworks.filter(h => h._id !== action.meta.arg.id);
      state.assignedQuizzes.quizzes = state.assignedQuizzes.quizzes.filter(h => h._id !== action.meta.arg.id);
    });
    builder.addCase(deleteGroupAsync.pending, (state, action) => {
      state.deleteGroupReq.status = 'loading';
    });
    builder.addCase(deleteGroupAsync.rejected, (state, action) => {
      state.deleteGroupReq.status = 'failed';
    });
    builder.addCase(deleteGroupAsync.fulfilled, (state, action) => {
      state.deleteGroupReq.status = 'succeeded';
      state.getGroupsReq.status = 'idle';
    });
    builder.addCase(createStudentAsync.pending, (state, action) => {
      state.createStudentReq.status = 'loading';
    });
    builder.addCase(editStudentAsync.pending, (state, action) => {
      state.editStudentReq.status = 'loading';
    });
    builder.addCase(editParentAsync.pending, (state, action) => {
      state.editParentReq.status = 'loading';
    });
    builder.addCase(createStudentAsync.rejected, (state, action) => {
      state.createStudentReq.status = 'failed';
      state.createStudentReq.error = action.error.message || 'Unknown error';
    });
    builder.addCase(editStudentAsync.rejected, (state, action) => {
      state.editStudentReq.status = 'failed';
      state.editStudentReq.error = action.error.message || 'Unknown error';
    });
    builder.addCase(editParentAsync.rejected, (state, action) => {
      state.editParentReq.status = 'failed';
      state.editParentReq.error = action.error.message || 'Unknown error';
    });

    builder.addCase(editAssistantAsync.pending, (state, action) => {
      state.editAssistantReq.status = 'loading';
    });
    builder.addCase(editAssistantAsync.fulfilled, (state, action) => {
      state.editAssistantReq.status = 'succeeded';
    });
    builder.addCase(editAssistantAsync.rejected, (state, action) => {
      state.editAssistantReq.status = 'failed';
      state.editAssistantReq.error = action.error.message || 'Unknown error';
    });

    builder.addCase(deleteAssistantAsync.pending, (state, action) => {
      state.deleteAssistantReq.status = 'loading';
    });
    builder.addCase(deleteAssistantAsync.fulfilled, (state, action) => {
      state.deleteAssistantReq.status = 'succeeded';
    });
    builder.addCase(deleteAssistantAsync.rejected, (state, action) => {
      state.deleteAssistantReq.status = 'failed';
      state.deleteAssistantReq.error = action.error.message || 'Unknown error';
    });

    builder.addCase(createQuizAsync.fulfilled, (state, action) => {
      state.createQuiz.name = '';
      state.createQuiz.durationInMinutes = 0;
      state.createQuiz.groups = [];
      state.createQuiz.req.status = 'succeeded';
    });
    builder.addCase(createQuizAsync.pending, (state, action) => {
      state.createQuiz.req.status = 'loading';
    });
    builder.addCase(createQuizAsync.rejected, (state, action) => {
      state.createQuiz.req.status = 'failed';
    });
    builder.addCase(createHomeworkAsync.fulfilled, (state, action) => {
      state.createQuiz.name = '';
      state.createQuiz.durationInMinutes = 0;
      state.createQuiz.groups = [];
      state.createQuiz.req.status = 'succeeded';
    });
    builder.addCase(createHomeworkAsync.pending, (state, action) => {
      state.createQuiz.req.status = 'loading';
    });
    builder.addCase(createHomeworkAsync.rejected, (state, action) => {
      state.createQuiz.req.status = 'failed';
    });
    builder.addCase(createAssistantAsync.pending, (state, action) => {
      state.createAssistant.req.status = 'loading';
    });
    builder.addCase(createAssistantAsync.fulfilled, (state, action) => {
      state.createAssistant.name = '';
      state.createAssistant.email = '';
      state.createAssistant.phone = '';
      state.createAssistant.password = '';
      state.createAssistant.req.status = 'succeeded';
    });
    builder.addCase(createAssistantAsync.rejected, (state, action) => {
      state.createAssistant.req.status = 'failed';
    });
    builder.addCase(createParentAsync.fulfilled, (state, action) => {
      state.createParent.name = '';
      state.createParent.email = '';
      state.createParent.phone = '';
      state.createParent.password = '';
      state.createParent.studentsIds = [];
      state.createParent.req.status = 'succeeded';
    });
    builder.addCase(createParentAsync.pending, (state, action) => {
      state.createParent.req.status = 'loading';
    });
    builder.addCase(createParentAsync.rejected, (state, action) => {
      state.createParent.req.status = 'failed';
    });
    builder.addCase(getQuizHomeworkDetailsAsync.fulfilled, (state, action) => {
      state.assignedTaskDetails.details = action.payload;
      state.assignedTaskDetails.req.status = 'succeeded';
    });
    builder.addCase(getQuizHomeworkDetailsAsync.pending, (state, action) => {
      state.assignedTaskDetails.req.status = 'loading';
    });
    builder.addCase(getQuizHomeworkDetailsAsync.rejected, (state, action) => {
      state.assignedTaskDetails.req.status = 'failed';
    });
    builder.addCase(getTaskCorrectionDetailsAsync.fulfilled, (state, action) => {
      state.taskCorrection.details = action.payload;
      state.taskCorrection.req.status = 'succeeded';
    });
    builder.addCase(getTaskCorrectionDetailsAsync.pending, (state, action) => {
      state.taskCorrection.req.status = 'loading';
    });
    builder.addCase(getTaskCorrectionDetailsAsync.rejected, (state, action) => {
      state.taskCorrection.req.status = 'failed';
    });
    builder.addCase(getStudentTasksAsync.pending, (state, action) => {
      state.studentTasks.req.status = 'loading';
    });
    builder.addCase(getStudentTasksAsync.fulfilled, (state, action) => {
      state.studentTasks.tasks = action.payload.tasks;
      state.studentTasks.studentDetails = action.payload.studentDetails;
      state.studentTasks.req.status = 'succeeded';
    });
    builder.addCase(getStudentTasksAsync.rejected, (state, action) => {
      state.studentTasks.req.status = 'failed';
      state.studentTasks.req.error = action.error.message || 'Unknown error';
    });
    builder.addCase(getStudentSpeakingProgressAsync.pending, (state, action) => {
      state.studentSpeakingVideoTasks.req.status = 'loading';
    });
    builder.addCase(getStudentSpeakingProgressAsync.fulfilled, (state, action) => {
      state.studentSpeakingVideoTasks.speakingVideoTasks = action.payload;
      state.studentSpeakingVideoTasks.req.status = 'succeeded';
    });
    builder.addCase(getStudentSpeakingProgressAsync.rejected, (state, action) => {
      state.studentSpeakingVideoTasks.req.status = 'failed';
      state.studentSpeakingVideoTasks.req.error = action.error.message || 'Unknown error';
    });

    builder.addCase(getQuizReportAsync.fulfilled, (state, action) => {
      state.quizReport.path = action.payload;
      state.quizReport.req.status = 'succeeded';
    });
    builder.addCase(getQuizReportAsync.pending, (state, action) => {
      state.quizReport.req.status = 'loading';
    });
    builder.addCase(getQuizReportAsync.rejected, (state, action) => {
      state.quizReport.req.status = 'failed';
    });
  },
});

export const {
  setCreateCategoryName,
  setCreateLevelName,
  setCreateDiplomaName,
  setCreateGradeName,
  setCreateQuizName,
  setCreateQuizActivity,
  setCreateQuizTime,
  setCreateQuizSelection,
  setCreateAssistantEmail,
  setCreateAssistantName,
  setCreateAssistantPassword,
  setCreateAssistantPhone,
  setCreateGroupAssistants,
  setCreateGroupName,
  setCreateGroupSchool,
  setCreateGroupStudents,
  setCreateVdoCipherInputs,
  resetModifyGroup,
  setModifyGroup,
  setModifyGroupName,
  setModifyGroupStudents,
  setModifyGroupAssistants,
  setCreateQuizGroups,
  setSchoolName,
  setVimVideoName,
  setVimVideoUrl,
  setModifyVimVideo,
  setModifyVimVideoActive,
  setModifyVimVideoName,
  setModifyVimVideoUrl,
  reassignTakenHomeworks,
  setCreateParentEmail,
  setCreateParentName,
  setCreateParentPassword,
  setCreateParentPhone,
  setCreateParentStudentsIds,
  setTreeViewName,
  setCreateQuizDueDate,
  setQuizReviewedState,
  setVideoCurrentTime,
  setIsVideoPaused,
} = slice.actions;

export const studentsSelector = (state: RootState) => state.admin.students;
export const questionsSelector = (state: RootState) => state.admin.questionRes.questions;
export const totalQuestionsSelector = (state: RootState) => state.admin.questionRes.total;
export const getStudentsReqSelector = (state: RootState) => state.admin.getStudentsReq;
export const getQuestionsReqSelector = (state: RootState) => state.admin.getQuestionsReq;
export const deleteQuestionReqSelector = (state: RootState) => state.admin.deleteQuestionReq;
export const deleteStudentReqSelector = (state: RootState) => state.admin.deleteStudentReq;
export const deleteParentReqSelector = (state: RootState) => state.admin.deleteParentReq;
export const createStudentReqSelector = (state: RootState) => state.admin.createStudentReq;
export const editStudentReqSelector = (state: RootState) => state.admin.editStudentReq;
export const editParentReqSelector = (state: RootState) => state.admin.editParentReq;
export const editAssistantReqSelector = (state: RootState) => state.admin.editAssistantReq;
export const deleteAssistantReqSelector = (state: RootState) => state.admin.deleteAssistantReq;
export const getGroupsReqSelector = (state: RootState) => state.admin.getGroupsReq;
export const getGradesReqSelector = (state: RootState) => state.admin.getGradesReq;
export const availableCategoriesSelector = (state: RootState) => state.admin.categories;
export const availableTreeViewsSelector = (state: RootState) => state.admin.treeViews.treeViews;
export const availableVimVideosSelector = (state: RootState) => state.admin.vimVideos.vimVideos;
export const getVdoCipherVideosSelector = (state: RootState) => state.admin.vdoCipherVideos.videos;
export const getSelectedVdoCipherSelector = (state: RootState) => state.admin.vdoCipherVideos.selectedVideo;
export const getVideoCurrentTimeSelector = (state: RootState) => state.admin.videoCurrentTime;
export const getIsVideoPausedSelector = (state: RootState) => state.admin.isVideoPaused;
export const threadReqSelector = (state: RootState) => state.admin.threadReq;
export const getVdoCipherVideosReqSelector = (state: RootState) => state.admin.vdoCipherVideos.req;
export const createVdoCipherInputsSelector = (state: RootState) => state.admin.createVdoCipher.videoInputs;
export const availableGroupsSelector = (state: RootState) => state.admin.groups;
export const availableDiplomasSelector = (state: RootState) => state.admin.diplomas;
export const availableLevelsSelector = (state: RootState) => state.admin.levels;
export const availableGradesSelector = (state: RootState) => state.admin.grades;
export const availableSchoolsSelector = (state: RootState) => state.admin.schools;
export const autoCorrectedQuizzesSelector = (state: RootState) => state.admin.autoCorrectedQuizzes;
export const assignedHomeworksSelector = (state: RootState) => state.admin.assignedHomeworks;
export const assignedQuizzesSelector = (state: RootState) => state.admin.assignedQuizzes;
export const getQuizQuestionsSelectionSelector = (state: RootState) => state.admin.createQuiz.questions;
export const getSchoolsReqSelector = (state: RootState) => state.admin.getSchoolsReq;
export const deleteQuizReqSelector = (state: RootState) => state.admin.deleteQuizReq;
export const CreateQuizReqSelector = (state: RootState) => state.admin.createQuiz.req;
export const CreateAssistantReqSelector = (state: RootState) => state.admin.createAssistant.req;
export const getAssistantReqSelector = (state: RootState) => state.admin.getAssistantsReq;
export const assistantsSelector = (state: RootState) => state.admin.assistants;
export const quizGroupsSelector = (state: RootState) => state.admin.createQuiz.groups;
export const reassignQuizzesReqSelector = (state: RootState) => state.admin.reassignQuizzesReq;
export const assignedTaskDetailsSelector = (state: RootState) => state.admin.assignedTaskDetails;
export const taskCorrectionSelector = (state: RootState) => state.admin.taskCorrection;
export const studentTasksSelector = (state: RootState) => state.admin.studentTasks;
export const studentSpeakingVideoTasksSelector = (state: RootState) => state.admin.studentSpeakingVideoTasks;
export const publishGradesReqSelector = (state: RootState) => state.admin.publishGradesReq;
export const quizReportSelector = (state: RootState) => state.admin.quizReport;
export default slice.reducer;
