import React from 'react';
import Dictaphone from './dictaphone/Dictaphone';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SpeakLinesModalProps {
  isOpen: boolean;
  subtitle?: string;
  subtitleEnd?: number;
  onSubmit: () => void;
}

const SpeakLinesModal: React.FC<SpeakLinesModalProps> = ({ isOpen, subtitle, subtitleEnd, onSubmit }) => {
  if (!subtitle && subtitle !== '') return null;

  return (
    <Dialog
      open={isOpen}
      onClose={onSubmit}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="alert-dialog-slide-title">{'Speak The lines'}</DialogTitle>
      <DialogContent>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            className="mb-7 text-center text-2xl font-bold text-dark"
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '2rem',
              color: 'black',
              marginBottom: '1.75rem',
              lineHeight: '2.5rem',
            }}
          >
            {subtitle}
          </div>
          <Dictaphone micId={'934riew4rj'} onClose={onSubmit} example={subtitle} subtitleEnd={subtitleEnd} size={50} />
        </div>
      </DialogContent>

      <IconButton color="primary" onClick={onSubmit}>
        <FontAwesomeIcon size="1x" icon="times-circle" />
        Skip
      </IconButton>
    </Dialog>
  );
};

export default SpeakLinesModal;
