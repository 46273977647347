import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { Box, Button, CardActionArea, CardActions, IconButton } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { tokenSelector, userSelector } from 'features/auth/authSlice';
import noCamera from 'assets/img/no-camera.png';
import {
  deleteVdoCipherAsync,
  setCreateVdoCipherInputs,
  // setEditVdoCipherDate,
  // setEditVdoCipherDescription,
  // setEditVdoCipherGroups,
  // setEditVdoCipherTitle,
} from 'features/admin/slices/adminSlice';
import { OptionType } from 'components/Autocomplete/Autocomplete';
import { RootState } from 'features';
import DeleteDialog from 'components/Dialog/DeleteDialog';
import VideoPlayer from 'components/Video/VideoPlayer';
import VideoForm from 'features/admin/views/VideoForm';

interface Props {
  id: string;
  title: string;
  description: string;
  url?: string;
  vdoCipherId: string;
  groups: OptionType[];
  assignedDate: Date;
  otpObj?: { otp: string; playbackInfo: string };
  posterImgUrl?: string;
  subtitles: Types.API.VdoCipher.ISubtitle[];
  type: string;
}

const VideoCard: React.FC<Props> = ({
  id,
  vdoCipherId,
  assignedDate,
  url,
  title,
  description,
  groups,
  otpObj,
  posterImgUrl,
  subtitles,
  type,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const [openEditDialogue, setOpenEditDialogue] = React.useState(false);
  const [openDeleteDialogue, setOpenDeleteDialogue] = React.useState(false);

  const token = useSelector(tokenSelector);
  const videoPlayerUrl = `https://player.vdocipher.com/v2/?otp=${otpObj?.otp}&playbackInfo=${otpObj?.playbackInfo}`;

  const handleEditVideo = () => {
    setOpenEditDialogue(true);

    dispatch(
      setCreateVdoCipherInputs({
        title,
        description,
        assignedDate: new Date(assignedDate),
        type: type || 'vdo-lect',
        groups: groups.map(g => g.id),
        subtitles,
        vdoCipherId,
      }),
    );
  };

  const handleDeleteVideo = () => {
    dispatch(
      deleteVdoCipherAsync({
        token,
        videoId: id,
      }),
    );
    setOpenDeleteDialogue(false);
  };
  return (
    <>
      <Card sx={{ width: 350, height: 430 }}>
        <CardActionArea
          onClick={() => {
            window.open(`${user.role === 'admin' ? 'admin' : 'student'}/video/${id}`);
          }}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <CardMedia component="img" height="240" src={posterImgUrl || noCamera} />

            <Box position="absolute" bgcolor="white" borderRadius={50}>
              <IconButton aria-label="play/pause">
                <PlayArrowIcon fontSize="large" color="primary" />
              </IconButton>
            </Box>
          </Box>
        </CardActionArea>

        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
        <CardActions sx={{ m: 1 }}>
          {user.role === 'admin' ? (
            <>
              <VideoForm
                openDialogue={openEditDialogue}
                setOpenDialogue={setOpenEditDialogue}
                edit={true}
                videoId={id}
                vdoCipherId={vdoCipherId}
                type={type as 'vdo-lect' | 'vdo-speaking'}
              />

              <DeleteDialog
                dialogText="Are you sure you want to delete this video?"
                onSubmitClick={handleDeleteVideo}
                open={openDeleteDialogue}
                onClose={() => setOpenDeleteDialogue(false)}
              />

              <Button color="primary" size="medium" variant="contained" onClick={handleEditVideo}>
                Edit Video
              </Button>
              <Button color="error" size="medium" variant="contained" onClick={() => setOpenDeleteDialogue(true)}>
                Delete Video
              </Button>
            </>
          ) : (
            <Button
              color="primary"
              size="medium"
              variant="contained"
              onClick={() => {
                window.open(`${user.role === 'admin' ? 'admin' : 'student'}/video/${id}`);
              }}
            >
              Watch Video
            </Button>
          )}
        </CardActions>
      </Card>
    </>
  );
};

export default VideoCard;
