import React from 'react';
import { ColDef, DataGrid, RowModel } from '@material-ui/data-grid';
import CloudDownload from '@material-ui/icons/CloudDownload';

import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from 'features/auth/authSlice';
import { useReqListener } from 'hooks/useReqListener';
import Button from 'components/CustomButtons/Button';
import { getStudentSpeakingProgressAsync, studentSpeakingVideoTasksSelector } from '../slices/adminSlice';

import styles from 'assets/jss/lms/features/questions';
import { makeStyles } from '@material-ui/core';
import CardHeader from 'components/Card/CardHeader';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { downloadStudentReport } from 'features/helpers';

const useStyles = makeStyles(styles);

interface Props {}
const StudentSpeakingVideosManagement: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const token = useSelector(tokenSelector);
  const { studentId } = useParams<{ studentId: string }>();
  const studentTasks = useSelector(studentSpeakingVideoTasksSelector)?.speakingVideoTasks;
  const studentTasksReq = useSelector(studentSpeakingVideoTasksSelector).req;

  const studentName = localStorage.getItem('chosenStudentForSpeakingVideosTasks') || 'No Name';
  const getRows = (): any[] => {
    if (!studentTasks) {
      return [];
    } else {
      const rows: RowModel[] = studentTasks.map((progress, index) => ({
        id: index.toString(),
        ...progress,
      }));
      return rows;
    }
  };

  const columns: ColDef[] = [
    { field: 'id', headerName: 'Index', width: 90 },
    { field: 'videoTitle', headerName: 'Video Title', width: 400 },
    {
      field: 'percentage',
      headerName: 'Percentage',
      width: 150,
      renderCell: params => (
        <div style={{ color: parseFloat(params?.value?.toString() || '0') < 60 ? 'red' : 'green' }}>
          {params.row.percentage}
        </div>
      ),
    },
    {
      field: '',
      headerName: 'Status',
      width: 150,
      renderCell: params => (
        <div
          style={{
            backgroundColor:
              parseFloat(params.row.percentage) > 90
                ? 'blue'
                : parseFloat(params.row.percentage) > 75
                ? 'green'
                : parseFloat(params.row.percentage) >= 60
                ? 'yellow'
                : parseFloat(params.row.percentage) >= 50
                ? 'orange'
                : 'red',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            borderRadius: '4px',
          }}
        >
          <span
            style={{
              color: parseFloat(params.row.percentage) > 75 ? 'white' : 'black',
              fontWeight: 'bold',
            }}
          >
            {parseFloat(params.row.percentage) > 90
              ? 'Excellent'
              : parseFloat(params.row.percentage) > 75
              ? 'Very Good'
              : parseFloat(params.row.percentage) >= 60
              ? 'Good'
              : parseFloat(params.row.percentage) >= 50
              ? 'pass'
              : 'Bad'}
          </span>
        </div>
      ),
    },
  ];

  useReqListener({
    status: studentTasksReq.status,
    successMessage: 'Successfully fetched student tasks',
    loadingMessage: 'Fetching student tasks',
    errorMessage: studentTasksReq.error,
  });

  React.useEffect(() => {
    if (studentId && dispatch && studentTasksReq.status === 'idle') {
      dispatch(getStudentSpeakingProgressAsync({ studentId, token }));
    }
  }, [studentId, dispatch, studentTasksReq.status, token]);

  const handleDownloadReport = () => {
    const newRows = studentTasks.map((r, index) => ({
      index: index,
      videoTitle: r.videoTitle,
      percentage: r.percentage,
      Status:
        parseFloat(r.percentage) > 90
          ? 'Excellent'
          : parseFloat(r.percentage) >= 75
          ? 'Very Good'
          : parseFloat(r.percentage) >= 60
          ? 'Good'
          : parseFloat(r.percentage) >= 50
          ? 'Pass'
          : 'Bad',
    }));
    const [firstName, secondName, lastName] = studentName.split(' ');
    const splittedName = lastName
      ? `${firstName}_${secondName}_${lastName}`
      : secondName
      ? `${firstName}_${secondName}`
      : `${firstName}`;

    const finalName = splittedName + '_Video_speaking';
    downloadStudentReport(newRows, columns, finalName);
  };

  return (
    <div
      style={{ height: '80vh', width: '100%', display: 'flex', flexDirection: 'column', alignContent: 'flex-start' }}
    >
      <CardHeader
        color="primary"
        className={classes.cardHeader}
        style={{
          marginTop: '20px',
        }}
      >
        {true && <h6 style={{ fontWeight: 'bolder', fontSize: 'large' }}> {studentName}'s Progress</h6>}
      </CardHeader>
      <div
        className={classNames(classes.container, classes.categoryAutocomplete)}
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button color="success" round onClick={handleDownloadReport}>
            Download Report <CloudDownload style={{ marginLeft: '8px' }} className={classes.icons} />
          </Button>
        </div>
      </div>
      <DataGrid
        rows={getRows()}
        pagination
        columns={columns}
        density="comfortable"
        autoHeight
        loading={studentTasksReq.status === 'loading'}
      />
    </div>
  );
};

export default StudentSpeakingVideosManagement;
