import { useEffect, useRef, useState } from 'react';
import useVdocipher from 'hooks/useVdocipher';
import VideoStatusAndSubtitle from './VideoStatusAndSubtitle';
import './style.css';

interface VideoPlayerProps {
  subtitles: any[];
  // pauses?: string;
  otp?: string;
  playbackInfo?: string;
}

export interface ISubtitle {
  start: number;
  end: number;
  text: string;
}

export enum Status {
  Playing = 'Playing',
  Paused = 'Paused',
  Ready = 'Ready',
  NA = 'NA',
}

function timeParser(time: string): number {
  const [minute, second] = time.split(':') as [string, string];
  return parseInt(minute) * 60 + parseInt(second);
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ subtitles, otp, playbackInfo }) => {
  const pausesRef = useRef(subtitles.map(item => timeParser(item.end)));
  const [subtitle, setSubtitle] = useState<ISubtitle | null>(null);

  const subtitlesRef = useRef<ISubtitle[]>(
    subtitles.map(item => ({ ...item, start: timeParser(item.start), end: timeParser(item.end) })),
  );
  const { loadVideo, isAPIReady } = useVdocipher();
  const videoContainerRef = useRef<HTMLDivElement | null>(null);
  const [videoRef, setVideoRef] = useState<HTMLIFrameElement | null>(null);

  const parsedSubtitles = subtitles.map(item => ({
    ...item,
    start: timeParser(item.start),
    end: timeParser(item.end),
  }));

  const addVideo = async () => {
    if (!videoContainerRef.current || !otp || !playbackInfo) return;
    const video = loadVideo({
      otp,
      playbackInfo,
      container: videoContainerRef.current,
      configuration: {
        // autoplay: true
        litemode: false,
        controls: 'on',
        ccLanguage: 'ar',
      },
    });
    if (!videoRef) setVideoRef(video);
  };

  useEffect(() => {
    if (!isAPIReady) return;
    addVideo();

    return () => {
      setVideoRef(null);
    };
  }, [isAPIReady]);

  return (
    <>
      <div className="vdo-container" ref={videoContainerRef}>
        <VideoStatusAndSubtitle
          subtitle={subtitle}
          setSubtitle={setSubtitle}
          videoRef={videoRef}
          isAPIReady={isAPIReady}
          subtitlesRef={subtitlesRef}
          pausesRef={pausesRef}
          allSubtitles={parsedSubtitles}
        />
      </div>
    </>
  );
};

export default VideoPlayer;
